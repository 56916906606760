import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    capitalizeText,
    handleImageCrops,
    mapCartData,
    replaceDashToWhitespace,
    triggerAccordion
} from "../../functions/functions.jsx";
import { formatPriceLabel } from "../../functions/price-functions.jsx";
import '../../../scss/ThankYou.scss';
import '../../../scss/loading.scss';
import { useMediaQuery } from "react-responsive";
import {useGlobalState} from "../../GlobalContext.jsx";

export const ThankYouPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [croppedImages, setCroppedImages] = useState([]);
    const showMobileView = useMediaQuery({ maxDeviceWidth: 479 });

    const { headerData, setHeaderData } = useGlobalState();

    useEffect(() => {
        if(Object.keys(headerData).length === 0) {
            const cachedConfiguratorData = localStorage.getItem('cachedConfiguratorData');
            if (cachedConfiguratorData) {
                const parsedData = JSON.parse(cachedConfiguratorData);
                if (parsedData.headerData) {
                    setHeaderData(parsedData.headerData);
                }
            }
        }
    }, [headerData]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const quotationId = queryParams.get('typc_id');
        const token = queryParams.get('typc_tkn');

        // Verify the token
        if (!token || !quotationId) {
            navigate('/'); // Redirect to the homepage if the token or quotationId is invalid
            return;
        }

        if (Object.keys(headerData).length > 0 && quotationId) {
            fetchQuotationData(quotationId, token).then();
        }
    }, [location, headerData, navigate]);

    const fetchQuotationData = async (id, token) => {
        // Change the host to the correct environment when testing headless thank you page.
        let host = "aanvragen.degalux.com";

        let fetchApiUrl = `${window.location.protocol}//${host}/wp-json/degalux-conf/v1/fetch_quotation_data/${id}?token=${token}`;

        // Prepare headers object
        let headers = {
            'Content-Type': 'application/json'
        };

        // Conditionally add Http-X-Wp-Api-Token header if not localhost
        if (window.location.host !== "localhost:3030") {
            headers['Http-X-Wp-Api-Token'] = headerData['REST']['restApi'];
        }

        try {
            const response = await fetch(fetchApiUrl, {
                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                throw new Error('Failed to fetch quotation data');
            }

            const quotationData = await response.json();

            if(!data) {
                setData({
                    ...quotationData,  // Spread the fetched data directly
                });
            }
        } catch (error) {
            console.error('Error fetching quotation data:', error);
        }
    };

    useEffect(() => {
        if (!!data) {
            handleImageCrops(data.oldCartData, setCroppedImages);
        }
    }, [data]);

    if (!data) {
        return <div>Loading...</div>;
    }

    const { createdQuotationData, createdQuotationDate, oldCartData, selectedConfigurator, totalPrice } = data;

    return (
        <div className="container --complete">
            <div className="order-overview">
                <span className="title">Order overzicht</span>
                <aside className="cart thank-you-page">
                    <div className="cart-card">
                        <p className="section-title">Overzicht
                            <span className="items-count">
                                {`(${Object.keys(oldCartData).length} ${Object.keys(oldCartData).length === 1 ? 'artikel' : 'artikelen'})`}
                            </span>
                        </p>
                        <div className="order">
                            {Object.values(oldCartData).map((item, productIndex) => (
                                <div key={"A" + Object.keys(item).length + productIndex} className="product-data">
                                    {!!croppedImages && croppedImages.length > 0 && croppedImages.map((imageSet, index) => (
                                        <>
                                            {productIndex === index && (
                                                <div className="preview-product" key={index}>
                                                    <img src={imageSet.innerImage}
                                                         alt="Binnenwerk"/>
                                                    <img src={imageSet.outerImage}
                                                         alt="Buitenkant"/>
                                                </div>
                                            )}
                                        </>
                                    ))}
                                    {!showMobileView ? (
                                        <div className="info">
                                            <span
                                                className="product-title">{item.type?.typeVal || capitalizeText(item.data.nameConfigurator)}</span>
                                            <div className="configuration-details accordion --collapsed">
                                                <div className="accordion-wrapper">
                                                    <div>
                                                        {Object.entries(mapCartData(item, selectedConfigurator)).map(([key, value]) => (
                                                            key !== "data" && key && value && (
                                                                <div className="detail-container" key={key}>
                                                                    <span
                                                                        className="product-section">{!key.includes('_') ? replaceDashToWhitespace(capitalizeText(key)) + ":" : replaceDashToWhitespace(capitalizeText(key), true) + ":"}</span>
                                                                    <span
                                                                        className="product-section-value">{typeof value === 'object' ? value.typeVal : value}</span>
                                                                </div>
                                                            )
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="accordion-trigger --small" onClick={(e) => {
                                                triggerAccordion(e, ["Toon meer", "Toon minder"]);
                                            }}>
                                                Toon meer
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="mobile-info-container">
                                            <div className="info">
                                            <span
                                                className="product-title">{item.type?.typeVal || capitalizeText(item.data.nameConfigurator)}</span>
                                                <div className="configuration-details accordion --collapsed">
                                                    <div className="accordion-wrapper">
                                                        <div>
                                                            {Object.entries(mapCartData(item, selectedConfigurator)).map(([key, value]) => (
                                                                key !== "data" && key && value && (
                                                                    <div className="detail-container" key={key}>
                                                                    <span
                                                                        className="product-section">{!key.includes('_') ? replaceDashToWhitespace(capitalizeText(key)) + ":" : replaceDashToWhitespace(capitalizeText(key), true) + ":"}</span>
                                                                        <span
                                                                            className="product-section-value">{typeof value === 'object' ? value.typeVal : value}</span>
                                                                    </div>
                                                                )
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="accordion-trigger --small" onClick={(e) => {
                                                    triggerAccordion(e, ["Toon meer", "Toon minder"]);
                                                }}>
                                                    Toon meer
                                                </button>
                                            </div>
                                            <div className="unit-price">
                                                {formatPriceLabel(Object.values(item.data.allPrices).reduce((acc, curr) => acc + curr, 0), true, true)}
                                            </div>
                                        </div>
                                    )}
                                    {!showMobileView && (
                                        <div className="unit-price">
                                            {formatPriceLabel(Object.values(item.data.allPrices).reduce((acc, curr) => acc + curr, 0), true, true)}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="footer">
                            <div className="total-price">
                                <span className="price-label">Productprijs</span>
                                <span className="price">{formatPriceLabel(totalPrice, true, true)}</span>
                            </div>
                            <div className={`caution-text green-caution-text`}>
                                <p><span>Let op:</span> deze prijs is exclusief montagekosten. Vraag een offerte aan
                                    voor de prijs inclusief montagekosten.</p>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
            {createdQuotationData?.acf?.customer_info && (
                <div className="customer-info">
                    <span>Jouw gegevens</span>
                    <div className="contact-section">
                        <p className="section-title">Contact gegevens</p>
                        <p className="full-name">{createdQuotationData.acf.customer_info.first_name + " " + createdQuotationData.acf.customer_info.last_name}</p>
                        <p className="phone-nr">{createdQuotationData.acf.customer_info.phone_nr}</p>
                        <p className="email">{createdQuotationData.acf.customer_info.email}</p>
                    </div>
                    <div className="delivery-section">
                        <p className="section-title">Adres</p>
                        <p className="street">{createdQuotationData.acf.customer_info.street + " " + createdQuotationData.acf.customer_info.house_nr}</p>
                        <p className="postal">{createdQuotationData.acf.customer_info.zipcode}</p>
                        <p className="country">{createdQuotationData.acf.customer_info.country}</p>
                    </div>
                    <div className="quotation-section">
                        <p className="section-title">Offerte details</p>
                        <p className="id">{"Nummer: " + createdQuotationData.id}</p>
                        {createdQuotationDate && <p className="date">{"Datum: " + createdQuotationDate}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ThankYouPage;
