import React from "react";
import "../../scss/Error.scss";

export default function Error({ error, resetErrorBoundary }) {
  const onErrorReset = () => {
    window.location.search = '';
    localStorage.clear();
    window.location.reload();
  }
  return (
    <>
      {/* <Header /> */}

      <div className={"error-component-container"}>
        <div className="container">
          <div className="icon"></div>

          <h1>Er is iets fout gegaan.</h1>
          <p>
            Foutje! Momenteel lijkt de configurator niet helemaal goed te laden
            door een technische fout. Maar geen paniek! Wij rennen al door onze
            fabriek om het te fixen. Probeer het nog een keer en voor je het
            weet, stel jij jouw mooiste zonwering samen.
          </p>
          <p>
            Lukt het écht niet? Stuur dan een mailtje naar{" "}
            <a href="mailto:verkoop@degalux.com">verkoop@degalux.com.</a>
          </p>
          {/*{error && <pre style={{ color: "red" }}>{error.message}</pre>}*/}
          <button onClick={() => onErrorReset()} className="button">
            Probeer opnieuw
          </button>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
}
