const formatPriceLabel = (price, euroSign = true, commaSeparator = false) => {
  // Convert the price to a floating-point number with 2 decimal places
  let formattedPrice = parseFloat(price).toFixed(2);

  if (commaSeparator) {
    // Separate the integer part and the decimal part
    let parts = formattedPrice.split('.');

    // Add thousands separator to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Join the integer and decimal parts with a comma
    formattedPrice = parts.join(',');

    // Handle the special case for zero decimal values (e.g., 100.00 should be 100,-)
    if (parts[1] === '00') {
      formattedPrice = formattedPrice.replace(',00', ',-');
    }
  } else {
    // Handle the special case for zero decimal values (e.g., 100.00 should be 100,-)
    formattedPrice = formattedPrice.replace('.00', ',-');
  }

  // Add the Euro symbol and format as desired for the Netherlands (NL)
  return `${euroSign ? '€' : ''}${formattedPrice}`;
};

const getInitialPrice = (priceData, fieldData, conf, isRits) => {
  if (!!priceData) {
    // Define a function to find the lowest key in an object
    const findLowestKey = (object) => {
      let lowestKey = null;
      let lowestValue = null;

      // Loop through the object
      for (const key in object) {
        if (!isNaN(object[key])) {
          // Check if the value is a number
          const value = parseInt(object[key]);

          if (lowestValue === null || value < lowestValue) {
            // If it's lower than the current lowest value, update the lowest key and value
            lowestKey = key;
            lowestValue = value;
          }
        }
      }

      return lowestValue; // Return the lowest value
    };

    // Get the initial price table based on the configuration and fieldData
    const initialPriceTable = fetchCorrectPriceTable(conf, fieldData, priceData, '', '', isRits);

    // Return the initial price based on the lowest key from the priceTable
    return {correctPrice: findLowestKey(initialPriceTable.table), data: initialPriceTable};
  }

  return null; // Return null if no matching table is found
};

const selectTableAndPrice = (data, heightValue, widthValue, typeCheck = false, isSunScreen = false) => {
  // Helper function to find the key closest to a given value in an object
  const findClosestKey = (object, value) => {
    // Ensure value is a number
    value = parseFloat(value);

    // Filter keys to ensure they are numeric and sort them
    let keys = Object.keys(object)
        .map(key => parseFloat(key))
        .filter(key => !isNaN(key)) // Ensure keys are numeric
        .sort((a, b) => a - b);

    if (keys.length === 0) return null;

    for (let i = 0; i < keys.length; i++) {
      // Exact match or in-between keys handling
      if (value === keys[i] || (value < keys[i] && i + 1 < keys.length && value < keys[i + 1])) {
        return keys[i].toString();
      }
    }

    // Return the highest key if value is greater than all keys
    return keys[keys.length - 1].toString();
  };

  const findClosestHeight = (allTables, heightValue, widthValue) => {
    // Ensure heightValue is a number
    heightValue = parseFloat(heightValue);
    widthValue = parseFloat(widthValue);

    const findEmptyValueKey = (object) => {
      let prevKey = null;
      for (const [key, value] of Object.entries(object)) {
        if (value === '') {
          return { emptyValueKey: parseInt(key), prevEmptyValueKey: parseInt(prevKey) };
        }
        prevKey = key;
      }
      return null;
    };

    const filterTables = (allTables, isAluPlus, compareKeyValue) => {
      return allTables.filter(tableArray => {
        // Check if any table within tableArray meets the condition
        const hasMatchingTable = tableArray.some(table => {
          if (!isAluPlus && table[compareKeyValue] === '') {
            return true;
          }
          if (isAluPlus && table[compareKeyValue] !== '') {
            return true;
          }
          return false;
        });
        return hasMatchingTable;
      });
    };


    let emptyValueKey, prevEmptyValueKey;
    const result = findEmptyValueKey(allTables[0][0]);
    if (result !== null) {
      ({emptyValueKey, prevEmptyValueKey} = result);
    }
    let filteredTables = allTables;
    if(!!emptyValueKey && !!prevEmptyValueKey) {
      let isAluPlus = widthValue >= prevEmptyValueKey;

      // Filter table based on alu or alu plus tables.
      filteredTables = filterTables(allTables, isAluPlus, emptyValueKey);
    }


    // Initialize variables to store the closest height
    let closestHeight = null;

    // Iterate over allTables
    for (const tableArray of filteredTables) {
      for (const table of tableArray) {
        // Parse the height from the current table
        const tableHeight = parseFloat(table.height);

        // If tableHeight is exactly equal to heightValue, return heightValue immediately
        if (tableHeight === heightValue) {
          return heightValue;
        }

        // If tableHeight is greater than heightValue and (closestHeight is null or tableHeight is less than closestHeight), update closestHeight
        if (tableHeight > heightValue && (closestHeight === null || tableHeight < closestHeight)) {
          closestHeight = tableHeight;
        }
      }
    }

    // If no closestHeight is found (i.e., all table heights are less than heightValue), find the maximum height
    if (closestHeight === null) {
      closestHeight = Math.max(...allTables.flat().map(table => parseFloat(table.height)));
    }

    // Return the closest height
    return closestHeight;
  };

  const findClosestHeightInPremium = (table, heightValue, widthValue) => {
    // Ensure heightValue and widthValue are numbers
    heightValue = parseFloat(heightValue);
    widthValue = parseFloat(widthValue);

    // Initialize variables to store the closest height and the index of the row
    let closestHeight = null;
    let closestHeightIndex = null;

    // Iterate over the table to find the closest height
    for (const [index, row] of table.entries()) {
      // Parse the height from the current row
      const rowHeight = parseFloat(row.height);

      // If rowHeight is exactly equal to heightValue, return heightValue immediately
      if (rowHeight === heightValue) {
        return { closestHeight: heightValue, rowIndex: index };
      }

      // If rowHeight is greater than heightValue and (closestHeight is null or rowHeight is less than closestHeight), update closestHeight and index
      if (rowHeight > heightValue && (closestHeight === null || rowHeight < closestHeight)) {
        closestHeight = rowHeight;
        closestHeightIndex = index;
      }
    }

    // If no closestHeight is found (i.e., all row heights are less than heightValue), find the maximum height
    if (closestHeight === null) {
      closestHeight = Math.max(...table.map(row => parseFloat(row.height)));
      closestHeightIndex = table.findIndex(row => parseFloat(row.height) === closestHeight);
    }

    // Return the closest height and the index of the row
    return { closestHeight, rowIndex: closestHeightIndex };
  };


  // Initialize variables to store the selected table and price
  let selectedTable = null;
  let selectedPrice = null;
  let selectedHousingType = null;
  let selectedHousingSize = null;

  let iterableData = data;

  if(isSunScreen) {
    iterableData = data.table;

    if(!widthValue) {
      widthValue = Object.keys(iterableData)[0];
    }

    const {closestHeight, rowIndex} = findClosestHeightInPremium(iterableData, heightValue, widthValue);
    if(parseFloat(iterableData[rowIndex]['height']) === closestHeight) {
      // Find the closest key to widthValue in the current table
      const closestWidthKey = findClosestKey(iterableData[rowIndex], widthValue);


      // Retrieve the price for the closest key
      const price = parseFloat(iterableData[rowIndex][closestWidthKey]);

      // If the price is not filled in for the given width, continue to the next iteration
      if (isNaN(price)) {
        return false;
      }

      selectedTable = iterableData[rowIndex];
      selectedPrice = price;
    }

  } else {
    // Iterate through the data, which contains multiple tables
    for (const item of iterableData) {
      if(typeCheck === "solar ritsscreen" && item['houzing_size'].includes('st-85')) {
        continue;
      }

      if(!isSunScreen) {
        for (const table of item.table) {
          // If widthValue is not defined, use the first key from the table
          if(!widthValue) {
            widthValue = Object.keys(table)[0];
          }

          // Find the closest height key of all price tables.
          const allTables = iterableData.map(item => item.table);
          const closestHeight = findClosestHeight(allTables, heightValue, widthValue);

          // If the current height is the closest height, select the current table
          if(parseFloat(table['height']) === closestHeight) {
            // Find the closest key to widthValue in the current table
            const closestWidthKey = findClosestKey(table, widthValue);


            // Retrieve the price for the closest key
            const price = parseFloat(table[closestWidthKey]);

            // If the price is not filled in for the given width, continue to the next iteration
            if (isNaN(price)) {
              continue;
            }

            selectedTable = table;
            selectedPrice = price;
            selectedHousingType = item['houzing_type'];
            selectedHousingSize = item['houzing_size'];
          }
        }
      }
      // If a table has been selected, break the outer loop as well
      if (selectedTable !== null) {
        break;
      }
    }
  }

  // Return the selected table and price as an object
  return { table: selectedTable, price: selectedPrice, housingType: selectedHousingType, housingSize: selectedHousingSize };
};
const handleInitialPriceOnFieldDataChange = (get, set, fieldData, priceData, conf, extraPriceToDimensions) => {
  if (!!fieldData && !!priceData) {
    // Check if both fieldData and priceData are available

    // Initialize variables for minHeight and minWidth
    let minHeight;
    let minWidth;

    if (!!fieldData.type && fieldData.type.typeKey === 'screen') {
      // If the fieldData type is 'Screen', select the appropriate price data
      const correctTableForScreen = priceData[0];
      minHeight = correctTableForScreen['price_table'][0].table[0].height;
      minWidth = Object.keys(correctTableForScreen['price_table'][0].table[0])[0];
    } else if (!!fieldData.type && fieldData.type.typeKey === 'ritsscreen' || !!fieldData.type && fieldData.type.typeKey === 'solar ritsscreen') {
      // For ritsscreen & solar ritsscreen
      const correctTableForScreen = priceData[1];
      minHeight = correctTableForScreen['price_table'][0].table[0].height;
      minWidth = Object.keys(correctTableForScreen['price_table'][0].table[0])[0];
    } else if (!!fieldData.type && fieldData.type.typeKey === "zonnescherm" && !!fieldData["uitvoering"] && fieldData["uitvoering"]["typeKey"] === "premium") {
      const correctTableForPremium = priceData['price_table_premium'][0];
      minHeight = correctTableForPremium['table'][0].height;
      minWidth = Object.keys(correctTableForPremium['table'][0])[0];
    }

    // Get the width and height values from fieldData, or use the calculated minHeight and minWidth
    const widthValue = !!fieldData['afmeting-breedte'] ? parseFloat(fieldData['afmeting-breedte']) : minWidth;
    const heightValue = !!fieldData['afmeting-hoogte'] ? parseFloat(fieldData['afmeting-hoogte']) : minHeight;

    // Create a clone of the 'get' object to update the price fields
    const updatePriceObject = { ...get };

    // Fetch the correct price table based on the configurator, fieldData, and priceData
    const priceTableObject = fetchCorrectPriceTable(conf, fieldData, priceData, widthValue, heightValue);

    if (!!priceTableObject.price) {
      updatePriceObject['initial'] = parseFloat(priceTableObject.price);
      let extraPriceValue;




      if(!!extraPriceToDimensions) {
        if(Object.keys(extraPriceToDimensions).length > 0) {
          Object.entries(extraPriceToDimensions).forEach(([extraPriceKey, extraPriceField]) => {
            if(!!fieldData[extraPriceKey]) {
              const heightInMM = !!fieldData['afmeting-hoogte'] ? parseFloat(fieldData['afmeting-hoogte']) : minHeight;
              const currentHeight = Math.ceil((heightInMM / 1000))
              extraPriceField.forEach((extraPriceObj) => {
                let transformedOptionKey = extraPriceObj.option_key.replace(/-/g, ' ');
                transformedOptionKey = transformedOptionKey.charAt(0).toUpperCase() + transformedOptionKey.slice(1);
                if(fieldData[extraPriceKey] === transformedOptionKey) {
                  extraPriceValue = parseFloat(extraPriceObj.price) * currentHeight;
                } else if(extraPriceObj.priceAdded) {
                  extraPriceValue = false;
                }
              });
            }
          });
        }
      }

      if(!extraPriceValue && updatePriceObject['extra_height_price']) delete updatePriceObject['extra_height_price'];

      if(!!extraPriceValue) updatePriceObject['extra_height_price'] = extraPriceValue;

      if(JSON.stringify(get) !== JSON.stringify(updatePriceObject)) set(updatePriceObject);
    }
    return priceTableObject;
  }
};

const fetchCorrectPriceTable = (selectedConfigurator, fieldData, priceData, widthValue, heightValue, isRits) => {
  // Check if the selectedConfigurator is "screen"
  const isScreenConfigurator = selectedConfigurator === "screen";
  const isPremiumSunScreen = selectedConfigurator === "zonnescherm" && !!fieldData["uitvoering"] && fieldData["uitvoering"]['typeKey'] === "premium";

  // Check if fieldData has keys, indicating that it's not the initial load
  const hasFieldData = Object.keys(fieldData).length > 0;

  // Function to retrieve the default table when needed
  const getDefaultTable = (selectTable = 0) => {
    // Get the first table and its properties
    const firstTable = !!priceData[0] ? priceData[0]['price_table'][0].table[0] : priceData['price_table'][0].table[0];
    const minHeight = firstTable.height;
    const minWidth = Object.keys(firstTable)[0];

    // Return the result of selectTableAndPrice with the default values
    return selectTableAndPrice(!!priceData[selectTable] ? priceData[selectTable]['price_table'] : priceData['price_table'], minHeight, minWidth);
  };

  // Initialize the priceTableObject
  let priceTableObject;

  if (isScreenConfigurator) {
    // For "screen" configurator
    if (hasFieldData) {
      // If fieldData has keys
      const typeCheck = !!fieldData['type'] ? ['solar ritsscreen', 'ritsscreen'].find(type => fieldData['type'].typeKey.includes(type)) : false;
      // Select the appropriate table and price based on the typeCheck
      priceTableObject = selectTableAndPrice(
          typeCheck ? priceData[0]['price_table'] : priceData[1]['price_table'],
          heightValue,
          widthValue,
          typeCheck
      );
    } else {
      // If it's the initial load, get the default table
      priceTableObject = getDefaultTable(1);
    }
  } else if (isPremiumSunScreen) {
    priceTableObject = selectTableAndPrice(
        priceData['price_table_premium'][0],
        heightValue,
        widthValue,
        false,
        true
    );
  } else {
    // For configurators other than "screen"
    if (!widthValue && !heightValue) {
      // If both width and heightValue are undefined, get the default table
      priceTableObject = getDefaultTable();
    } else {
      // Otherwise, select the appropriate table and price based on the provided values
      priceTableObject = selectTableAndPrice(priceData['price_table'], heightValue, widthValue);
    }
  }

  // Return the final priceTableObject
  return priceTableObject;
};

const calculateTotalPrice = (allPrices) => {
  // Use reduce to accumulate the total price based on 'allPrices'
  return Object.values(allPrices).reduce((total, price) => {
    return total + price;
  }, 0);
}

const processPriceTables = (priceData) => {
  const tablesWithZeroValue = []; // Array to hold tables that contain '0' value

  if (priceData.price_table) {
    priceData.price_table.forEach((tableObj) => {
      tableObj.table.forEach((table) => {
        // Check if any value in the table is '0'
        const hasZeroValue = Object.values(table).some(value => value === '0');

        if (hasZeroValue) {
          // If the table has a '0' value, save the whole table
          tablesWithZeroValue.push(table);
        }
      });
    });
  }
  return tablesWithZeroValue; // Return the tables that contain '0' value
};


export {
  formatPriceLabel,
  getInitialPrice,
  handleInitialPriceOnFieldDataChange,
  fetchCorrectPriceTable,
  selectTableAndPrice,
  calculateTotalPrice,
  processPriceTables
};
