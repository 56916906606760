import React, {useEffect} from "react";

const HubspotShareQuotationFolderForm = (props) => {
    const {fullLoadedForm, setFullLoadedForm, url} = props;

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '20157360',
                    formId: '91532817-eb37-4025-8a3a-c947b9d5e895',
                    region: 'na1',
                    target: '#hubspotForm',
                    onFormReady: function(form) {
                        if(!!form) {
                            console.log(form);
                            console.log(form.querySelector('input[name="persoonlijke_offerte_url"]'))
                            form.querySelector('input[name="persoonlijke_offerte_url"]').value = JSON.parse(url);
                        }
                    }
                })
            }
        });
    }, []);

    useEffect(() => {
        if(!fullLoadedForm) {
            const formElement = document.getElementById('hubspotForm');
            if(formElement) {
                setTimeout(() => {
                    setFullLoadedForm(true);
                },1000);
            }
        }
    },[fullLoadedForm]);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );

}

export default HubspotShareQuotationFolderForm;