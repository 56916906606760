import {useEffect, useState} from "react";
import "../../scss/Footer.scss"
import {Link, useNavigate} from 'react-router-dom';
import apiFetch from "@wordpress/api-fetch";
import {basicFetchRequest} from "../functions/functions.jsx";
import {useGlobalState} from "../GlobalContext.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
export default function Footer(props) {
    const {
        editProduct, setEditedProduct,
        fieldsData, setFieldsData,
        setSelectedConfigurator,
        setActiveField,
        setStatusQuotationFolder,
        setCompleteQuotation,
        quotationFolder,
        configuratorHubspotUrl
    } = useGlobalState();
    const {data, selectedConfigurator, setHeaderData} = props;
    const navigate = useNavigate();

    const handleClickToHome = () => {
        setEditedProduct(""); // Remove editing in product when user clicks home button
        setFieldsData({}); // Remove old fieldsData
        setSelectedConfigurator("") // Remove selected configurator
        setActiveField(0);
        setStatusQuotationFolder(false);
        setCompleteQuotation(false);
        navigate(`/${configuratorHubspotUrl}`);
    }

    return (
        <>
            {/* @TODO: Remove hardcoded values and replace with dynamic fields */}
            <div className="footer main-content">
                <div className="left">
                    <img onClick={() => handleClickToHome()} src={"https://aanvragen.degalux.com/wp-content/uploads/2023/10/Degalux_Logo.svg"} alt="Logo"/>
                </div>
                <div className="right">
                    <a href="https://wa.me/0342404130">
                        <div className="whatsapp">
                            <FontAwesomeIcon icon={faWhatsapp} className="icon-background"/>
                            <div>
                                <p>Contact via WhatsApp</p>
                                <span>Elke werkdag bereikbaar</span>
                            </div>
                        </div>
                    </a>
                    <a href="mailto:verkoop@degalux.com">
                        <div className="email">
                            <FontAwesomeIcon icon={faEnvelope} className="icon-background"/>
                            <div>
                                <p>Mail naar Degalux</p>
                                <span>Binnen 1 werkdag antwoord</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
}