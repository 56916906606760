import React, { useEffect, useRef, useState } from "react";
import "../../../scss/Fields.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faArrowDown,
  faArrowUp,
  faFolderOpen,
} from "@fortawesome/pro-regular-svg-icons";
import RadioField from "./components/RadioField.jsx";
import MeasurementField from "./components/MeasurementField.jsx";
import ColorSelectField from "./components/ColorSelectField.jsx";
import EngineField from "./components/EngineField.jsx";
import InitialCheck from "../../popups/InitialCheck.jsx";
import {
  getInitialPrice,
  handleInitialPriceOnFieldDataChange, processPriceTables,
} from "../../functions/price-functions.jsx";
import InfoPopup from "../../popups/InfoPopup.jsx";
import { useNavigate } from "react-router-dom";
import {
  capitalizeText,
  handleConditionalLogic,
} from "../../functions/functions.jsx";
import { CSSTransition } from "react-transition-group";
import CardField from "./components/CardField.jsx";

export default function Fields(props) {
  const {
    data,
    setData,
    priceData,
    colorData,
    setColorData,
    engineData,
    setPriceFromFields,
    priceFromFields,
    selectedConfigurator,
    conditionalLogic,
    setConditionalLogic,
    appliedConditionalLogic,
    sendFieldData,
    activeField,
    setActiveField,
    fieldsData,
    setFieldsData,
    quotationFolder,
    editProduct,
    priceUpdating,
    setPriceUpdating,
    selectedColors,
    setSelectedColors,
    extraPriceRules
  } = props;
  const [colorObject, setColorObject] = useState(null);
  const [openInitialCheck, setInitialCheck] = useState(false);
  const [activeInfoPopup, setActiveInfoPopup] = useState(false);
  const [houzingTypeState, setHousingTypeState] = useState(null);
  const [houzingSizeState, setHousingSizeState] = useState(null);
  const [initialData, setInitialData] = useState([...data]);
  const [componentData, setComponentData] = useState([...data]);
  const [missingPriceData, setMissingPriceData] = useState(null);
  const [extraFilledPrice, setExtraFilledPrice] = useState(null);
  const [runInitialDataOnce, setRunInitialDataOnce] = useState(false);
  const [initialKeyData, setInitialKeyData] = useState(null);
  const [captureMeasurementError, setCaptureMeasurementError] = useState(false);


  const infoPopupRef = useRef(null);
  const navigate = useNavigate();

  // filteredComponentData is the componentData array with only the active items. (Based on conditional logic)
  let filteredComponentData = !!componentData && componentData.filter(item => item.isActive);

  const checkedInputStyle = {
    // background: "linear-gradient(180deg, rgb(100, 188, 118) 0, rgb(179, 222, 133) 100%)",
    // color: "#fff",
    borderColor: 'var(--color-3)'
  };

  useEffect(() => {
    // Set initial price when priceData is available, on initial load of the field component (do not run if priceFromFields is already set by URL parameters).
    if (!!priceData) {
      // CorrectPrice is the price value of the object returned from getInitialPrice function.
      // We use data to set the housing type and size.
      const { correctPrice} = getInitialPrice(
        priceData,
        fieldsData,
        selectedConfigurator,
      );
      setPriceFromFields({ initial: correctPrice });

      // HandleCondtionalLogic on initial load(for url parameters).
      handleConditionalLogic(data, setConditionalLogic, conditionalLogic);

      if(Object.keys(processPriceTables(priceData).length > 0)) {
        setMissingPriceData(processPriceTables(priceData));
      }
    }
  }, []);

  useEffect(() => {
    if (isDebugModeActive()) {
      console.log("price from fields: ", priceFromFields);
      console.log("fieldsData: ", fieldsData);
      console.log("extraPriceRules: ", extraPriceRules);
    }
  }, [priceFromFields, fieldsData, extraPriceRules]);

  useEffect(() => {
    if(!!fieldsData && !!fieldsData.type && !runInitialDataOnce ) {
      setInitialKeyData({
        ['type']: fieldsData.type
      })
      setRunInitialDataOnce(true);
    }
  }, [fieldsData, runInitialDataOnce]);

  useEffect(() => {
    // Hardcoded set type for zonnescherm ( In new version of configurator(wordpress) type is removed
    if(selectedConfigurator === "zonnescherm" && !fieldsData['type']) {
      setFieldsData({
        ...fieldsData,
        ["type"]: {
          typeKey: "zonnescherm",
          typeVal: "Zonnescherm"
        }
      });
    }
  }, [fieldsData, selectedConfigurator])

  useEffect(() => {
    // Trigger initialCheck popup when activeField is one.
    if (activeField === 1 && !editProduct) {
      setInitialCheck(false); // Set to true if initial popup should be activated('offerte aanvragen' or 'verder configureren')
    }

    if (filteredComponentData.length !== 0 && activeField === filteredComponentData.length) {
      if (
        fieldsData["kast_type"] !== houzingTypeState &&
        fieldsData["kastmaat"] !== houzingSizeState
      ) {
        setFieldsData({
          ...fieldsData,
          ["kast_type"]: houzingTypeState,
          ["kastmaat"]: houzingSizeState,
        });
      } else {
        sendFieldData(fieldsData);
        setActiveField(0);
      }
    } else {
      sendFieldData(fieldsData, false);
    }
  }, [activeField, filteredComponentData, fieldsData, houzingTypeState, houzingSizeState]);

  useEffect(() => {
    let element = document.querySelector("div[data-fieldnumber='" + activeField + "']");
    if (!!activeField && activeField !== filteredComponentData.length && element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [activeField]);

  useEffect(() => {
    if (
        (activeField > 0 || editProduct) &&
        fieldsData
    ) {
      setPriceUpdating(true);

      const { housingType, housingSize } = handleInitialPriceOnFieldDataChange(
          priceFromFields,
          setPriceFromFields,
          fieldsData,
          priceData,
          selectedConfigurator,
          extraFilledPrice
      );

      if (housingType && housingType !== houzingTypeState) {
        setHousingTypeState(capitalizeText(housingType));
      }

      if (housingSize && housingSize !== houzingSizeState) {
        setHousingSizeState(housingSize);
      }

      setTimeout(() => {
        setPriceUpdating(false);
      }, 1000);
    }

    if(!!Object.keys(priceFromFields).length > 0 && !!Object.keys(fieldsData).length > 0) {
      const url = new URL(window.location.href);
      url.searchParams.set("configurator", JSON.stringify(selectedConfigurator));
      url.searchParams.set("priceData", JSON.stringify(priceFromFields));
      url.searchParams.set("configuration", JSON.stringify(fieldsData));

      const newUrl = `${window.location.pathname}${url.search}`;

      if (window.location.href !== newUrl) {
        window.history.pushState(null, null, newUrl);
      }
    }
  }, [fieldsData, activeField, priceFromFields, extraFilledPrice, selectedConfigurator, priceData, editProduct]);

  useEffect(() => {
    if (fieldsData) {
      const keys = Object.keys(fieldsData);
      const newFieldsData = { ...fieldsData };

      if ((keys.includes("motor") || keys.includes("zender")) && !keys.includes("bediening-motor") && !keys.includes("bediening-zender")) {
        if (keys.includes("motor")) {
          newFieldsData["bediening-motor"] = { ...fieldsData["motor"] };
        }
        if (keys.includes("zender")) {
          newFieldsData["bediening-zender"] = { ...fieldsData["zender"] };
        }
      }

      if (JSON.stringify(newFieldsData) !== JSON.stringify(fieldsData)) {
        setFieldsData(newFieldsData);
      }
    }
  }, [fieldsData, editProduct]);

  // UseEffect to clear fieldsData for bedieningen if type value has been changed
  useEffect(() => {
    const shallowFieldData = {...fieldsData};
    if(!!initialKeyData) {
      const bedieningKeys = Object.keys(fieldsData).filter(key => (key.includes("bediening") || key.includes("motor" || key.includes("zender"))));
      if (fieldsData.type.typeKey !== initialKeyData.type.typeKey) {
        if(!!bedieningKeys && bedieningKeys.length > 0) {
          bedieningKeys.forEach(key => {
            delete shallowFieldData[key];
          })
        }
        if (JSON.stringify(shallowFieldData) !== JSON.stringify(fieldsData)) {
          setFieldsData(shallowFieldData);
        }
      }
    }
  }, [fieldsData.type, initialKeyData]); // Re-run this effect every time fieldsData.type changes

  useEffect(() => {
    const newFieldsData = { ...fieldsData };
    if (houzingSizeState) {
      newFieldsData["kastmaat"] = houzingSizeState;
    }
    if (houzingTypeState) {
      newFieldsData["kast_type"] = houzingTypeState;
    }
    if(JSON.stringify(newFieldsData) !== JSON.stringify(fieldsData)) {
      setFieldsData(newFieldsData);
    }
  }, [houzingTypeState, houzingSizeState, fieldsData]);

  useEffect(() => {
    // Set fieldsData to afgeschuind if solar rolluik is selected in "soort", this is a temporary solution.
    if (!!fieldsData["type"] && fieldsData["type"].typeKey === "solar rolluik")
      fieldsData["afwerking"] = "Afgeschuind";
  }, [fieldsData, selectedConfigurator, priceFromFields]);

  const handleNextField = (e, index) => {
    if (e.currentTarget.getAttribute("disabled") !== null) return false;

    const parentElement = e.target.closest(`.field-${index}`);

    if (activeField < componentData.length) {
      Object.keys(fieldsData).forEach((key) => {
        if (key === parentElement.dataset.name) {
          setActiveField(index + 1);
        } else if (key.includes(parentElement.dataset.name)) {
          setActiveField(index + 1);
        }
      });
    }
  };

  const isDebugModeActive = () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('debug') && params.get('debug') === 'true';
  };

  const handlePrevField = (e, index) => {
    const currentParentElement = e.target.closest(`.field-${index}`);
    const previousParentElement = currentParentElement ? currentParentElement.previousElementSibling : null;
    if (activeField > 0) {
      Object.keys(fieldsData).forEach((key) => {
        if (key === previousParentElement.dataset.name || key.includes(previousParentElement.dataset.name)) {
          setActiveField(index - 1);
        }
      });
    }
  };

  const concatenateAndLowercase = (collapseTitle, choice) => {
    const collapseTitleLower = collapseTitle.toLowerCase();
    const choiceLower = choice.toLowerCase();
    return collapseTitleLower + "-" + choiceLower;
  };

  const shouldDisplayNextButton = (item) => {
    // Check if measurementError is set.
    if(captureMeasurementError) {
      return false;
    }

    // Convert the item's label to lowercase
    const lowercaseCollapseTitle = item.label.toLowerCase();

    // @TODO: add options of bediening in form_field options
    // Check if the options exist and are of type "object"
    // if (!item.form_fields[0]["form_field"].options || typeof item.form_fields[0]["form_field"].options !== "object") {
    //     return false; // Hide the button if the options don't exist or are not an object
    // }

    if (
      item.form_fields[0]["form_field"]["form_field_type"] !== "measurements" &&
      item.form_fields[0]["form_field"]["form_field_type"] !== "controls" &&
      item.form_fields[0]["form_field"]["form_field_type"] !== "cards"
    ) {
      // For non-"measurements" form field type
      if (!fieldsData[lowercaseCollapseTitle]) {
        return false; // Hide the button if the field data doesn't exist
      }

      // Handle next step button seperated for "kleuren".
      if(item.key === "kleuren-stap") {
        const colorKeys = Object.keys(fieldsData).filter(key => key.includes("kleuren"));
        if(colorKeys.length === 3) {
          return true;
        }
      }

      // Check if any of the options match the field data
      return Object.keys(item.form_fields[0]["form_field"].options).some(
        (option) => {
          return (
              (
                  fieldsData[lowercaseCollapseTitle] === item.form_fields[0]["form_field"].options[option].label || fieldsData[lowercaseCollapseTitle]['typeVal'] === item.form_fields[0]["form_field"].options[option].label
              )
          );
        }
      );
    } else if (
      item.form_fields[0]["form_field"]["form_field_type"] === "measurements"
    ) {
      // For "measurements" form field type
      if (!!item.form_fields[0]["form_field"].options) {
        // Check if all specific field data exists in fieldsData
        const areAllFieldsExist = item.form_fields[0][
          "form_field"
        ].options.every((option) => {
          const fieldName = concatenateAndLowercase(
            lowercaseCollapseTitle,
            option.label
          );
          return !!fieldsData[fieldName];
        });

        // Hide the button if any specific field data is missing
        if (!areAllFieldsExist) {
          return false;
        }
      }

      // Check if any of the options have a value greater than 0
      return item.form_fields[0]["form_field"].options.some((option) => {
        const fieldName = concatenateAndLowercase(
          lowercaseCollapseTitle,
          option.label
        );
        return !!fieldsData[fieldName] > 0;
      });
    }

    else if (
        item.form_fields[0]["form_field"]["form_field_type"] === "controls"
    ) {
      // Custom check to see if all controls are selected based on found values in appliedLogic and fieldData.
      let checkControlsCount = item['form_fields'].length
      const keysForControls = Object.keys(appliedConditionalLogic).filter((logic) => logic.startsWith("bediening-"));

      if(keysForControls.length > 0) {
        checkControlsCount = checkControlsCount - keysForControls.length;
      }

      const bedieningKeys = Object.keys(fieldsData).filter(key =>
          (key.includes("bediening") && !["bediening-motor", "bediening-zender"].includes(key)) ||
          (key.includes("motor") && !key.includes("bediening") && !["bediening-motor"].includes(key)) ||
          (key.includes("zender") && !key.includes("bediening") && !["bediening-zender"].includes(key)) ||
          (["bediening-motor", "bediening-zender"].includes(key) && !key.includes("bediening")) ||
          (!Object.keys(fieldsData).includes("motor") && key.includes("bediening-motor")) ||
          (!Object.keys(fieldsData).includes("zender") && key.includes("bediening-zender"))
      );

      return bedieningKeys.length === checkControlsCount;
    }
    else if (
        item.form_fields[0]["form_field"]["form_field_type"] === "cards"
    ) {
      if (!fieldsData[lowercaseCollapseTitle]) {
        return false; // Hide the button if the field data doesn't exist
      }

      return Object.keys(item.form_fields[0]["form_field"]['options_cards']).some(
          (option) => {
            return (
                (
                    fieldsData[lowercaseCollapseTitle] === item.form_fields[0]["form_field"]["options_cards"][option].label || fieldsData[lowercaseCollapseTitle]['typeVal'] === item.form_fields[0]["form_field"]["options_cards"][option].label
                )
            );
          }
      );
    }
  };

  // Function to handle active field when selecting the div component, this makes sure user can change input if he wants to.
  const handleActiveFieldOnUserChange = (e, index) => {
    let stepFieldEvent = e.target.closest(".step-field");
    let stepFieldDataName = stepFieldEvent.dataset.name;

    if (
      stepFieldEvent.classList.contains("clickable") &&
      activeField !== index
    ) {
      setActiveField(index);
    }
  };

  const handleStepStatus = (item, index) => {
    if (!fieldsData) return "Nog te kiezen";

    const labelLowerCase = item.label.toLowerCase();
    // @INFO: index 3 = Afmeting & index 4 = Kleur
    if (item.key !== "afmeting-stap" && item.key !== "kleuren-stap") {
      if (item.key === "bediening-stap") {
        let matchingKey;
        if (labelLowerCase in fieldsData) {
          matchingKey = Object.keys(fieldsData).find(
              (key) => key.toLowerCase() === labelLowerCase
          );
        } else if (labelLowerCase + "-motor" in fieldsData) {
          matchingKey = Object.keys(fieldsData).find(
              (key) => key.toLowerCase() === labelLowerCase + "-motor"
          );
        } else if ("motor" in fieldsData) {
          matchingKey = Object.keys(fieldsData).find(
              (key) => key.toLowerCase() === "motor"
          );
        } else if (labelLowerCase + "-zender" in fieldsData) {
          matchingKey = Object.keys(fieldsData).find(
              (key) => key.toLowerCase() === labelLowerCase + "-zender"
          );
        }  else if ("zender" in fieldsData) {
          matchingKey = Object.keys(fieldsData).find(
              (key) => key.toLowerCase() === "zender"
          );
        }
        let returnObject = typeof fieldsData[matchingKey] === "object" ? fieldsData[matchingKey].typeVal : fieldsData[matchingKey];
        return matchingKey
          ? truncateIfLong(returnObject, 25)
          : "Nog te kiezen";
      }

      if (item.key === "montage-stap") {
        const matchingKey = Object.keys(fieldsData).find(
          (key) => key.toLowerCase() === labelLowerCase
        );
        return matchingKey
          ? truncateIfLong(fieldsData[matchingKey], 25)
          : "Nog te kiezen";
      }

      let itemValue = fieldsData[labelLowerCase];
      if (typeof itemValue === 'object' && itemValue !== null && 'typeVal' in itemValue) {
        itemValue = itemValue.typeVal;
      }

      return Object.keys(fieldsData).includes(labelLowerCase)
          ? `${truncateIfLong(itemValue, 25)}`
          : "Nog te kiezen";
    } else if (item.key === "afmeting-stap") {
      const widthAndHeight = Object.entries(fieldsData)
          .filter(([key]) => key.startsWith(labelLowerCase))
          .sort(([keyA], [keyB]) => {
            // Force 'afmeting-breedte' to come before 'afmeting-hoogte'
            if (keyA.includes("breedte")) return -1;
            if (keyB.includes("breedte")) return 1;
            return 0;
          })
          .map(([, value]) => value);

      if (
          widthAndHeight.length === 2 &&
          widthAndHeight.every((item) => !!item && item !== "0")
      ) {
        return `${widthAndHeight.join(" x ")}`;
      } else {
        return "Nog te kiezen";
      }
    } else if (item.key === "kleuren-stap") {
      const colorChoice = Object.entries(fieldsData)
        .filter(([key]) => key.startsWith(labelLowerCase))
        .map(([, value]) => value);

      return colorChoice.length >= 1
        ? `${truncateIfLong(colorChoice[0], 25)}`
        : "Nog te kiezen";
    }

    // Helper function to truncate text.
    function truncateIfLong(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + "...";
      }
      return str;
    }
  };

  const handleInfoPopupClick = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the outer radio field
    setActiveInfoPopup(!activeInfoPopup);
  };

  const handleClickableField = (item, stepIndex) => {
    // Check if measurementError is set.
    if(captureMeasurementError) {
      return false;
    }

    const label = item.label.toLowerCase();

    if (
        Object.keys(fieldsData).includes(label) ||
        Object.keys(fieldsData).includes(label + "-breedte") ||
        Object.keys(fieldsData).includes(label + "-hoogte") ||
        Object.keys(fieldsData).includes("zender") ||
        Object.keys(fieldsData).includes("motor") ||
        (Object.keys(fieldsData).includes(label + "-zender") &&
            stepIndex !== activeField)
    ) {
      return "clickable";
    }
  };

  useEffect(() => {
    if (!data || !initialData || !conditionalLogic) {
      return;
    }

    const keepArray = initialData.map((item) => {
      if (!item["form_fields"]) {
        return true;
      }

      return item["form_fields"].some((formField) => {
        return !(
          formField["form_field"]["conditional_logic_disable_step"] === true &&
          formField["form_field"]["apply_conditional_logic"]
        );
      });
    });

    if (!keepArray.some((item) => !item)) {
      const updatedData = initialData.map(item => ({
        ...item,
        isActive: true
      }));
      setComponentData(updatedData);
      return;
    }

    const falseIndices = keepArray.reduce((acc, item, index) => {
      if (!item) {
        acc.push(index);
      }
      return acc;
    }, []);

    const newData = initialData.filter(
      (item, index) => !falseIndices.includes(index)
    );
    if (JSON.stringify(newData) !== JSON.stringify(data)) {
      setComponentData(newData);
    }
  }, [data, conditionalLogic]);

  const getNextActiveLabel = (renderIndex) => {
    let nextActiveLabel;
    for (let i = renderIndex + 1; i < Object.values(filteredComponentData).length; i++) {
      if (Object.values(filteredComponentData)[i]['isActive']) {
        nextActiveLabel = Object.values(filteredComponentData)[i]['label'];
        break;
      }
    }
    return nextActiveLabel;
  }

  return (
      <>
        {openInitialCheck && <InitialCheck trigger={openInitialCheck} />}
        {Object.values(componentData).length > 0 &&
            Object.values(componentData)
                .filter(item => item.isActive)
                .map((item, stepIndex) => {
                  // NOTE: Conditional logic for regular fields is applied here.
                  // Fields like 'Color' and 'Engines' have their conditional logic handled within their respective components.
                  // If a form_field in the current item matches any key in 'appliedConditionalLogic' and 'conditional_logic_disable_step' is true, the field rendering is skipped.
                  if (Object.values(item['form_fields']).find((formField) =>
                      Object.keys(appliedConditionalLogic).includes(formField['form_field']['question_key']
                      ) && formField['form_field']['conditional_logic_disable_step'])) {
                    // remove the corresponding item from the componentData array & set state.
                    const updatedComponentData = componentData.map((component, index) => {
                      if (index === stepIndex) {
                        return {
                          ...component,
                          isActive: false
                        };
                      }
                      return component;
                    });
                    // only update state if the updatedComponentData is different from the current componentData.
                    if (JSON.stringify(updatedComponentData) !== JSON.stringify(componentData)) {
                      setComponentData(updatedComponentData);
                    }
                  }
                  return (
                      <React.Fragment key={item.label.toLowerCase() + stepIndex}>
                        <div
                            className={`step-field field-${stepIndex} ${handleClickableField(
                                item,
                                stepIndex
                            )}`}
                            data-name={item.label.toLowerCase()}
                            data-fieldnumber={stepIndex}
                            onClick={(e) => handleActiveFieldOnUserChange(e, stepIndex)}
                        >
                          <div
                              className={`field-header ${
                                  stepIndex === activeField ? "expand" : ""
                              }`}
                          >
                            <div
                                className={`step-container${
                                    Object.keys(fieldsData).some((key) =>
                                        (key.toLowerCase().includes(item.label.toLowerCase()) || (key.toLowerCase().includes('motor') || key.toLowerCase().includes('zender')))
                                    )
                                        ? " filled"
                                        : ""
                                }`}
                            >
                              <span className="count">{`${stepIndex + 1}.`}</span>
                              <span className="step-element"></span>
                            </div>
                            <div className="step-title">
                              <span>{item.label}</span>
                              {stepIndex === activeField && (
                                  <>
                                    <div>
                                      <span className="expanded-title">{item.title}</span>
                                      {item.key !== "bediening-stap" && (item.has_description || item.has_accordion) && (
                                          <span
                                              ref={infoPopupRef}
                                              onClick={(e) => handleInfoPopupClick(e)}
                                              className="expanded-info"
                                          >
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                size="1x"
                                style={{ color: "#000" }}
                            />
                          </span>
                                      )}
                                      {item.key === "bediening-stap" && (item.has_description || item.has_accordion) && !!fieldsData['type'] && fieldsData['type']['typeKey'] !== "solar rolluik" && (
                                          <span
                                              ref={infoPopupRef}
                                              onClick={(e) => handleInfoPopupClick(e)}
                                              className="expanded-info"
                                          >
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                size="1x"
                                style={{ color: "#000" }}
                            />
                          </span>
                                      )}
                                    </div>
                                    {!!activeInfoPopup && (
                                        <InfoPopup
                                            trigger={activeInfoPopup}
                                            text={item.description}
                                            set={setActiveInfoPopup}
                                            setTextParse={true}
                                            activeAccordion={item.has_accordion}
                                            accordionData={!!item.accordions && item.accordions}
                                        />
                                    )}
                                  </>
                              )}
                            </div>
                            {/* Only show step status if field is NOT expanded. */}
                            {stepIndex !== activeField && (
                                <p
                                    className={`step-status ${
                                        handleStepStatus(item, stepIndex) !== "Nog te kiezen"
                                            ? "checked"
                                            : ""
                                    }`}
                                >
                                  {handleStepStatus(item, stepIndex)}
                                </p>
                            )}
                          </div>
                          <CSSTransition
                              key={item.label.toLowerCase() + stepIndex}
                              in={stepIndex === activeField}
                              timeout={500}
                              classNames="field-transition"
                              unmountOnExit
                              appear={true}
                          >
                            <div
                                className={`field-body ${
                                    item.key !== "kleuren-stap" && item.form_fields[0]["form_field"]["apply_conditional_logic"] &&
                                    item.form_fields[0]["form_field"]["form_field_type"] !== "controls"
                                        ? item.form_fields[0]["form_field"]["apply_conditional_logic"]
                                        : ""
                                }`}
                            >
                              <div className="container-wrapper">
                                <div>
                                  <div className={`content-wrapper ${item.form_fields[0]['form_field']['question_key'] === "uitvoering-vraag"? 'container-cards' : ''}`}>
                                    {item.form_fields.map((formField, formFieldIndex) => {
                                      if(Object.keys(appliedConditionalLogic).includes(formField['form_field']['question_key'])) return null;
                                      return (
                                          <React.Fragment key={formFieldIndex}>
                                            {
                                                formField["form_field"]["form_field_type"] !== "controls" && !formField["form_field"]["hide_title"] && (
                                                    <span
                                                        className={`field-description ${item.key === "kleuren-stap" && 'inactive-description'}`}>
                                          {formField["form_field"].label}
                                        </span>
                                                )
                                            }

                                            <div
                                                className={`${
                                                    formField["form_field"]["form_field_type"] !== "measurements"
                                                        ? "field-input"
                                                        : "field-input-2"
                                                }`}
                                            >
                                              {Object.values(formField["form_field"].options).map(
                                                  (option, index) => (
                                                      <React.Fragment key={option + index}>
                                                        {formField["form_field"]["form_field_type"] === "radio" && (
                                                            <RadioField
                                                                item={item}
                                                                option={option}
                                                                index={formFieldIndex}
                                                                stepIndex={stepIndex}
                                                                fieldsData={fieldsData}
                                                                setFieldsData={setFieldsData}
                                                                priceFromFields={priceFromFields}
                                                                setPriceFromFields={setPriceFromFields}
                                                                conditionalLogic={conditionalLogic}
                                                                setConditionalLogic={setConditionalLogic}
                                                                appliedConditionalLogic={appliedConditionalLogic}
                                                                allFieldsData={data}
                                                                setAllFieldsData={setData}
                                                                priceData={priceData}
                                                                selectedConfigurator={selectedConfigurator}
                                                                setLabelWhite={
                                                                    fieldsData[item.label.toLowerCase()] === option.label
                                                                }
                                                                checkedInputStyle={checkedInputStyle}
                                                                setPriceUpdating={setPriceUpdating}
                                                                extraPriceRules={extraPriceRules}
                                                                getSelectedConfigurator={selectedConfigurator}
                                                                extraFilledPrice={extraFilledPrice}
                                                                setExtraFilledPrice={setExtraFilledPrice}
                                                            />
                                                        )}
                                                        {formField["form_field"]["form_field_type"] ===
                                                            "measurements" && (
                                                                <MeasurementField
                                                                    item={item}
                                                                    option={option}
                                                                    index={index}
                                                                    fieldsData={fieldsData}
                                                                    setFieldsData={setFieldsData}
                                                                    toLower={concatenateAndLowercase}
                                                                    setPriceFromFields={setPriceFromFields}
                                                                    selectedConfigurator={selectedConfigurator}
                                                                    missingPriceData={missingPriceData}
                                                                    priceData={priceData}
                                                                    componentData={componentData}
                                                                    setComponentData={setComponentData}
                                                                    stepIndex={stepIndex}
                                                                    setCaptureMeasurementError={setCaptureMeasurementError}
                                                                />
                                                            )}
                                                      </React.Fragment>
                                                  )
                                              )}
                                            </div>
                                            {formField["form_field"]["form_field_type"] === "controls" && (
                                                <EngineField
                                                    item={item}
                                                    index={formFieldIndex}
                                                    fieldsData={fieldsData}
                                                    setFieldsData={setFieldsData}
                                                    priceFromFields={priceFromFields}
                                                    setPriceFromFields={setPriceFromFields}
                                                    conditionalLogic={conditionalLogic}
                                                    setConditionalLogic={setConditionalLogic}
                                                    allFieldsData={data}
                                                    setAllFieldsData={setData}
                                                    engineData={engineData}
                                                    checkedInputStyle={checkedInputStyle}
                                                    setPriceUpdating={setPriceUpdating}
                                                    appliedConditionalLogic={appliedConditionalLogic}
                                                />
                                            )}
                                            {item.key === "kleuren-stap" && (
                                                <React.Fragment key={item.label + formFieldIndex}>
                                                  <ColorSelectField
                                                      item={item}
                                                      fieldsData={fieldsData}
                                                      setFieldsData={setFieldsData}
                                                      type={"radio"}
                                                      colorObject={colorObject}
                                                      setColorObject={setColorObject}
                                                      setPriceFromFields={setPriceFromFields}
                                                      priceFromFields={priceFromFields}
                                                      selectedConfigurator={selectedConfigurator}
                                                      colorData={colorData}
                                                      setColorData={setColorData}
                                                      setPriceUpdating={setPriceUpdating}
                                                      formFieldIndex={formFieldIndex}
                                                      selectedColors={selectedColors}
                                                      setSelectedColors={setSelectedColors}
                                                      setAllFieldsData={setData}
                                                      allFieldsData={data}
                                                      conditionalLogic={conditionalLogic}
                                                      appliedConditionalLogic={appliedConditionalLogic}
                                                  />

                                                </React.Fragment>
                                            )}
                                            <div
                                                className={`${
                                                    formField["form_field"]["form_field_type"] !== "measurements"
                                                        ? "field-input"
                                                        : "field-input-2"
                                                }`}
                                            >
                                              {item.key === "uitvoering-stap" && (
                                                  <>
                                                    {Object.values(formField["form_field"]["options_cards"]).map(
                                                        (option, index) => (
                                                            <React.Fragment key={option + index}>
                                                              {formField["form_field"]["form_field_type"] === "cards" && (
                                                                  <CardField
                                                                      item={item}
                                                                      option={option}
                                                                      index={formFieldIndex}
                                                                      stepIndex={stepIndex}
                                                                      fieldsData={fieldsData}
                                                                      setFieldsData={setFieldsData}
                                                                      priceFromFields={priceFromFields}
                                                                      setPriceFromFields={setPriceFromFields}
                                                                      conditionalLogic={conditionalLogic}
                                                                      setConditionalLogic={setConditionalLogic}
                                                                      appliedConditionalLogic={appliedConditionalLogic}
                                                                      allFieldsData={data}
                                                                      setAllFieldsData={setData}
                                                                      priceData={priceData}
                                                                      selectedConfigurator={selectedConfigurator}
                                                                      setLabelWhite={
                                                                          fieldsData[item.label.toLowerCase()] === option.label
                                                                      }
                                                                      checkedInputStyle={checkedInputStyle}
                                                                      setPriceUpdating={setPriceUpdating}
                                                                      extraPriceRules={extraPriceRules}
                                                                      getSelectedConfigurator={selectedConfigurator}
                                                                      extraFilledPrice={extraFilledPrice}
                                                                      setExtraFilledPrice={setExtraFilledPrice}
                                                                      type={"cards"}
                                                                  />
                                                              )}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                  </>
                                              )}
                                            </div>
                                        </React.Fragment>
                                    );
                                    })}
                                      <div className="field-footer">
                                        {!Object.values(componentData).length > 0 || stepIndex !== 0 && (
                                            <a
                                                className="button --tertiary prev-step"
                                                onClick={(e) => handlePrevField(e, stepIndex)}
                                          >
                                            <span>Vorige stap</span>
                                            <FontAwesomeIcon icon={faArrowUp} size="1x"/>
                                          </a>
                                      )}
                                      <a
                                          className="button next-step"
                                          disabled={!shouldDisplayNextButton(item)}
                                          onClick={(e) => handleNextField(e, stepIndex)}
                                      >
                                        {stepIndex + 1 <
                                        Object.values(filteredComponentData).length ? (
                                          <>
                                            <div>
                                              Volgende stap:{' '}
                                              <span>
                                  {
                                    getNextActiveLabel(stepIndex)
                                  }
                                </span>
                                            </div>
                                              <FontAwesomeIcon icon={faArrowDown} size="1x"/>
                                            </>
                                        ) : (
                                            <>
                                              <FontAwesomeIcon
                                                  icon={faFolderOpen}
                                                  size="1x"
                                              />
                                              <div className="final-span">
                                                Voeg toe aan
                                                <span>de offertemap</span>
                                              </div>
                                            </>
                                        )}
                                        <span></span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CSSTransition>
                        </div>
                      </React.Fragment>
                  )
                })
        }
      </>
  );
}
