import React from "react";
import { useState } from "react";
import "../../../scss/storybook-styles/ExpandedContainer.scss";

import { Button } from "./inc/Button";

export const ExpandContainer = ({ title, icon, iconExpand, customClass, ...props }) => {
    const [active, setActive] = useState(false);
    const toggleExpandContainer = (e) => {
        setActive(!active);
    };

    return (
        <div className={`expand-item${customClass !== undefined ? ` ${customClass}` : ''}`}>
            <Button
                onClick={(e) => toggleExpandContainer(e)}
                label={title}
                version="tertiary"
                icon_placement="right"
                icon={icon}
                iconExpand={iconExpand}
                aria-expanded={active ? "true" : "false"}
            />

            <div
                className={`expand-container`}
                aria-hidden={!active ? "true" : "false"}
            >
                <div>
                    <div className="content">{props.children}</div>
                </div>
            </div>
        </div>
    );
};