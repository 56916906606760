import React, {useState} from "react";
// import "../../../../scss/storybook-styles/Button.scss";

export const Button = ({
                           version,
                           icon_placement,
                           icon,
                           iconExpand,
                           label,
                           round,
                           disabled,
                           customClass,
                           href,
                           type,
                           onClick = (e) => { e = e; },
                           ...props
                       }) => {
    const buttonClasses = `button --${version}${
        icon_placement === "center" && icon !== undefined ? " --icon" : ""
    }${round === true ? " --rounded" : ""}${
        typeof customClass !== "undefined" ? ' ' + customClass : ""
    }`;

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = (e) => {
        setIsClicked(!isClicked);
        onClick(e);
    };

    const buttonIcon = isClicked ? iconExpand : icon;

    const content = (
        <>
            {icon_placement === "center" && icon !== undefined && (
                <span className={`icon ${icon}`}></span>
            )}
            {icon_placement === "left" && icon !== undefined && (
                <span className={`icon ${icon}`}></span>
            )}
            <span className="label">{label}</span>
            {icon_placement === "right" && icon !== undefined && (
                <span className={`icon ${buttonIcon}`}></span>
            )}
        </>
    );

    return href ? (
        <a href={href} className={buttonClasses} onClick={handleClick} {...props}>
            {content}
        </a>
    ) : (
        <button type={type} disabled={disabled} className={buttonClasses} onClick={handleClick} {...props}>
            {content}
        </button>
    );
};

Button.defaultProps = {
    version: "primary",
    icon: undefined,
    icon_placement: "left",
    round: false,
    customClass: undefined,
    disabled: false,
    href: undefined,
    type: "button",
};