import React from 'react';
import Popup from 'reactjs-popup';
import {useState, useEffect} from "react";
import '../../scss/Popup.scss'
import MediaCard from "../configurator/content/components/MediaCard.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsUp, faGear} from "@fortawesome/pro-regular-svg-icons";

export default function InitialCheck(props) {

    return (
        <>
            <Popup
                className={"initial-popup"}
                open={props.trigger}
                modal
            >
                {close => (
                    <div className="modal">
                        {/* @TODO: change class name of mediacard-container in this component, since this class is already being used for the MediaCard component */}
                        <div className="card-container">
                            <div className="card-content">
                                <h2>Heb je alles al op een rijtje?</h2>
                                <p>Weet jij al precies wat je wil en heb je alles al opgemeten? Ga dan verder met de uitgebreide configuratie. Wil jij zo snel mogelijk een offerte? Vraag ‘m dan direct aan, dan neemt een installateur contact op om de details door te nemen.</p>
                                {!!close && (
                                    <div className="popup-interaction">
                                        <button
                                            className="button"
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faThumbsUp} size="1x"/>
                                            Offerte aanvragen
                                        </button>
                                        <button
                                            className="button --secondary"
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faGear} size="1x"/>
                                            Verder configureren
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}