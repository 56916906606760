import React, {useEffect, useRef, useState} from "react";
import {declareLabelKey} from "../../../../functions/functions.jsx";
import "../../../../../scss/CustomSelectBox.scss";

export default function CustomSelectBox(props) {
    const { data, dataKey, customKey, item, fieldsData, toggle, sliceColors, handleInputInteraction, handleCustomColorSelect, boxForStep, setToggle, index, setFieldsData, typeKey, missingPriceData, priceData, selectedColors, setSelectedColors, mainKey, currentLabel, priceFromFields, appliedConditionalLogic, disabledEngineOptions } = props;
    const [localToggles, setLocalToggles] = useState(Array(data.length).fill(false));
    const [mostChosenLabel, setMostChosenLabel] = useState(null);
    const selectRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            selectRef.current &&
            !selectRef.current.contains(event.target) &&
            event.target.nodeName !== "INPUT" &&
            event.target.nodeName !== "LABEL"
        ) {
            setToggle(customKey, false);
        }
    };

    useEffect(() => {
        if (localToggles[customKey]) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [localToggles, customKey]);

    useEffect(() => {
        setLocalToggles(toggle);
    }, [setToggle, customKey]);

    useEffect(() => {
        if (!!currentLabel && ["lamellen", "doek", "omkasting"].includes(currentLabel)) {
            const selectedKey = `${item.label.toLowerCase()}-${currentLabel}-keuze`;
            const selectedChoice = fieldsData[selectedKey];

            if (selectedChoice) {
                let shouldUpdate = false;
                const newColors = { ...selectedColors };
                let isSelectedChoiceValid = false;

                // @TODO: select houzing or slats_canvas based on image key inside.
                data.options_colors.forEach(option => {
                    const { houzing, slats_canvas, slats_houzing } = option.acf;
                    let indexedOption;
                    switch(slats_houzing) {
                        case "houzing":
                            indexedOption = houzing;
                            break;
                        case "slats_canvas":
                            indexedOption = slats_canvas;
                            break;
                        default:
                            console.error("Invalid slats_houzing value for option: ", option);
                    }

                    if (indexedOption && indexedOption['color_name'] === selectedChoice) {
                        isSelectedChoiceValid = true; // selectedChoice is valid
                        const newColorValue = indexedOption['label_type'] === "image" ? indexedOption['label_image'].link : indexedOption['label_color'];
                        if (newColors[data.label.split(" ")[0].toLowerCase()] !== newColorValue) {
                            newColors[data.label.split(" ")[0].toLowerCase()] = newColorValue;
                            shouldUpdate = true;
                        }
                    }
                });

                if (shouldUpdate) {
                    setSelectedColors(newColors);
                }

                if (!isSelectedChoiceValid) {
                    const newFieldsData = { ...fieldsData };
                    delete newFieldsData[selectedKey];
                    delete newFieldsData[item.key];
                    setFieldsData(newFieldsData);
                }
            }
        }
    }, [fieldsData, data, selectedColors]);

    const isUrl = ( string ) => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(string);
    }

    const ColorSelectBox = () => {
        const [customSelectActive, setCustomSelectActive] = useState(false);
        const [managedOptionColors, setManagedOptionColors] = useState([]);
        const [colorsWithDisableLogic, setColorsWithDisableLogic] = useState([]); // Colors with disable logic

        /*
        - Apply conditional logic to the color options
        - The index of the disabled option will be saved and added in the disabled attribute of the element.
        - Initial setup - transforming options colors and setting the state
        - Hide logic is in here to make sure these options don't get rendered.
        */

        useEffect(() => {
            let optionsColorsCopy = [...data.options_colors]; // Create a shallow copy
            let transformedOptionsColors = optionsColorsCopy.map((option, index) => {
                const { houzing, slats_canvas, slats_houzing } = option.acf;
                let indexedOption;
                switch(slats_houzing) {
                    case "houzing":
                        indexedOption = houzing;
                        break;
                    case "slats_canvas":
                        indexedOption = slats_canvas;
                        break;
                    default:
                        console.error("Invalid slats_houzing value for option: ", option);
                }

                if(index === 0 && !option['conditional_logic_active']) {
                    // set default colors values - first color
                    if(!fieldsData[item.label.toLowerCase() + "-" + currentLabel + "-keuze"]) {
                        setFieldsData({
                            ...fieldsData,
                            [item.label.toLowerCase() + "-" + currentLabel + "-keuze"]: indexedOption['color_name'],
                        });
                    }
                }
                // Assuming color_price transformation logic is needed here
                if (!!indexedOption.color_price && !!priceFromFields.initial && parseInt(indexedOption.color_price) !== 0) {
                    const percentagePrice = parseFloat(priceFromFields.initial) / parseInt(indexedOption.color_price);
                    if(!isNaN(percentagePrice)) {
                        indexedOption.calculated_color_price = percentagePrice;
                    } else {
                        indexedOption.calculated_color_price = 0;
                    }
                }
                return { ...option, acf: { ...option.acf, [houzing ? 'houzing' : 'slats_canvas']: indexedOption } };
            });

            // Check if choice key of options matches the applied conditional logic state
            transformedOptionsColors = transformedOptionsColors.filter((option, index) => {
                const foundLogicKey = Object.keys(appliedConditionalLogic).find(key => key === option['choice_key'].split("–").join("-"));
                if(foundLogicKey) {
                    const foundLogicIndex = Object.keys(appliedConditionalLogic[foundLogicKey])[0];
                    if(!!foundLogicIndex) {
                        if (appliedConditionalLogic[foundLogicKey][foundLogicIndex]['show_hide'] === "hide") {
                            return false;
                        }
                        if (appliedConditionalLogic[foundLogicKey][foundLogicIndex]['show_hide'] === "disable" && !colorsWithDisableLogic.includes(option['choice_key'])) {
                            setColorsWithDisableLogic(prevState => [...prevState, option['choice_key']]);
                        }
                    }
                }
                return true; // Include this option
            });


            setManagedOptionColors(transformedOptionsColors);

            // Setting customSelectActive for a single option case
            if (Object.keys(data.options_colors).length === 1) {
                let option = Object.values(data.options_colors)[0];
                option = typeof option.acf.houzing === 'object' && !!option.acf.houzing.image ? option.acf.houzing : typeof option.acf.slats_canvas === 'object' && !!option.acf.slats_canvas.image ? option.acf.slats_canvas : null;
                handleCustomColorSelect(option, dataKey, customKey);
                setCustomSelectActive(true);
            }
        }, [data.options_colors, priceFromFields.initial, fieldsData, appliedConditionalLogic]); // Depend on data.options_colors and priceFromFields.initial for re-calculation

        return (
            <React.Fragment key={mainKey}>
                <fieldset className={`select-box ${item.key === "kleuren-stap" && 'inactive-margin'}`}>
                    <div className="box-container" key={dataKey + "-select-box"}>
                        <label>{data.label}</label>
                        <div
                            ref={selectRef}
                            className={`select-trigger ${customSelectActive ? 'custom-active' : ""}`}
                            onClick={() => setToggle(customKey, !localToggles[index])}
                        >
                            {!!fieldsData[item.label.toLowerCase() + "-" + currentLabel + "-keuze"] // data.label.split(" ")[0].toLowerCase()
                                ?
                                <>
                                    <div
                                        className="color-preview"
                                        style={!isUrl(selectedColors[currentLabel]) ? { backgroundColor: selectedColors[currentLabel] } : { backgroundImage: `url(${selectedColors[currentLabel]})` }}
                                    ></div>
                                    {fieldsData[item.label.toLowerCase() + "-" + currentLabel + "-keuze"]}
                                {/*  data.label.split(" ")[0].toLowerCase()  */}
                                </>
                                :
                                "Selecteer een optie"}
                        </div>
                        <ul className={localToggles[index] ? "select-items --open" : "select-items --closed"}>
                            {sliceColors
                                ? Object.values(managedOptionColors).slice(0, 4).map((option, index) => {
                                    const { houzing, slats_canvas, slats_houzing } = option.acf;
                                    let indexedOption;
                                    switch(slats_houzing) {
                                        case "houzing":
                                            indexedOption = houzing;
                                            break;
                                        case "slats_canvas":
                                            indexedOption = slats_canvas;
                                            break;
                                        default:
                                            console.error("Invalid slats_houzing value for option: ", option);
                                    }

                                    const backgroundStyle = indexedOption['label_type'] === "image"
                                        ? { backgroundImage: `url(${indexedOption['label_image'].link})` }
                                        : { backgroundColor: indexedOption['label_color'] };

                                    return (
                                        <li key={dataKey + "-" + currentLabel + "-optionKey"}>
                                            <div className="color-preview" style={backgroundStyle}></div>
                                            <input
                                                type={"radio"}
                                                id={dataKey + "-" + index + "-optionKey"} // Unique id for the input
                                                name={item.label.toLowerCase() + "-" + dataKey.toLowerCase() + "-keuze"}
                                                onChange={(e) => handleInputInteraction(e, indexedOption, dataKey, customKey)}
                                                value={indexedOption['color_name']}
                                                data-price={!!indexedOption['calculated_color_price'] ? indexedOption['calculated_color_price'] : "0"}
                                            />
                                            <label htmlFor={dataKey + "-" + index + "-optionKey"}>{indexedOption['color_name']}</label>
                                        </li>
                                    );
                                })
                                : Object.values(managedOptionColors).map((option, index) => {
                                    const { houzing, slats_canvas, slats_houzing } = option.acf;
                                    let indexedOption;
                                    switch(slats_houzing) {
                                        case "houzing":
                                            indexedOption = houzing;
                                            break;
                                        case "slats_canvas":
                                            indexedOption = slats_canvas;
                                            break;
                                        default:
                                            console.error("Invalid slats_houzing value for option: ", option);
                                    }

                                    const backgroundStyle = indexedOption['label_type'] === "image"
                                        ? { backgroundImage: `url(${indexedOption['label_image'].link})` }
                                        : { backgroundColor: indexedOption['label_color'] };

                                    return (
                                        <li key={dataKey + "-" + currentLabel + "-optionKey-" + index} className={colorsWithDisableLogic.includes(option['choice_key']) ? 'disable-conditional-logic' : null}>
                                            <div
                                                className="color-preview"
                                                style={backgroundStyle}
                                            ></div>
                                            <input
                                                type={"radio"}
                                                id={dataKey + "-" + index + "-optionKey"} // Unique id for the input
                                                name={item.label.toLowerCase() + "-" + currentLabel.toLowerCase() + "-keuze"}
                                                onChange={(e) => handleInputInteraction(e, indexedOption, currentLabel, customKey)}
                                                value={indexedOption['color_name']}
                                                data-price={!!indexedOption['calculated_color_price'] ? indexedOption['calculated_color_price'] : "0"}
                                                disabled={colorsWithDisableLogic.includes(option['choice_key'])}
                                            />
                                            <label
                                                className={colorsWithDisableLogic.includes(option['choice_key']) ? 'disable-conditional-logic' : null}
                                                htmlFor={dataKey + "-" + index + "-optionKey"}>{indexedOption['color_name']}</label>
                                        </li>
                                    );
                                })
                            }
                            {/* Show all colors option if length is bigger than 4. */}
                            {Object.values(data).length > 4 && (
                                <li key={dataKey + "-show-all-option"}>
                                    {/*<input*/}
                                    {/*    type={"radio"}*/}
                                    {/*    id={dataKey + "-show-all-option"} // Unique id for the input*/}
                                    {/*    name={item.label.toLowerCase() + "-" + dataKey.toLowerCase() + "-keuze"}*/}
                                    {/*    onChange={(e) => handleInputInteraction(e)}*/}
                                    {/*    value={"all-colors"}*/}
                                    {/*/>*/}
                                    {/*<label*/}
                                    {/*    htmlFor={dataKey + "-show-all-option"}>{sliceColors ? "Toon alle kleuren" : "Toon minder kleuren"}</label>*/}
                                </li>
                            )}
                        </ul>
                    </div>
                </fieldset>
            </React.Fragment>
        );
    };

    const OpennessBox = () => {
        const handleInputInteractionForOpenness = (e) => {
            e.stopPropagation();
            if(!!e.target) {
                setFieldsData({
                    ...fieldsData,
                    [e.target.name]: e.target.value,
                });
                setToggle(customKey, false);
            }
        };
        return (
            <>
            {index === 1 && (
                <>
                    <fieldset className="select-box">
                        <div className="box-container" key={dataKey + "-select-box"}>
                            <div>Openheidsfactor</div>
                            <div
                                ref={selectRef}
                                className="select-trigger"
                                onClick={() => setToggle(customKey, !localToggles[customKey])}
                            >
                                {!!fieldsData[dataKey] ? fieldsData[dataKey] : "Selecteer een optie"}
                            </div>
                        </div>
                        <ul className={localToggles[customKey] ? "select-items --open" : "select-items --closed"}>
                            {data.map((option, optionIndex) => (
                                <li key={dataKey + "-" + optionIndex} className={`input-radio`}>
                                    <input
                                        type={"radio"}
                                        id={dataKey + "-" + optionIndex}
                                        name={dataKey}
                                        onClick={(e) => {
                                            handleInputInteractionForOpenness(e);
                                        }}
                                        value={option}
                                        data-price={0}
                                    />
                                    <label
                                        htmlFor={dataKey + "-" + optionIndex}>{option}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </fieldset>
                </>
            )}
            </>
        )
            ;
    };

    const EngineSelectBox = () => {
        let fieldDataMotorToCheck = !!fieldsData[item.label.toLowerCase() + "-motor"] ? fieldsData[item.label.toLowerCase() + "-motor"] : fieldsData["motor"]
        let fieldDataZenderToCheck = !!fieldsData[item.label.toLowerCase() + "-zender"] ? fieldsData[item.label.toLowerCase() + "-zender"] : fieldsData["zender"]

        return (
            <>
                <div className="select-box">
                    <div
                        ref={selectRef}
                        className="select-trigger"
                        onClick={() => setToggle(customKey, !localToggles[customKey])}
                        data-index={index}
                        data-indexx={customKey}
                    >
                        {fieldsData['type']['typeKey'] !== "zonnescherm" ? (
                            customKey === 1
                                ? (fieldDataMotorToCheck
                                    ? <div>{fieldDataMotorToCheck['typeVal']}{mostChosenLabel &&
                                        <span className="most-chosen">{mostChosenLabel}</span>}</div>
                                    : "Selecteer een optie")
                                : customKey === 2
                                    ? (fieldDataZenderToCheck
                                        ? <div>{fieldDataZenderToCheck['typeVal']}{mostChosenLabel &&
                                            <span className="most-chosen">{mostChosenLabel}</span>}</div>
                                        : "Selecteer een optie")
                                    : (!!fieldsData[item.label.toLowerCase()]
                                        ? Array.isArray(fieldsData[item.label.toLowerCase()]['typeVal'])
                                            ? <div>{fieldsData[item.label.toLowerCase()].join(', ')}{mostChosenLabel &&
                                                <span className="most-chosen">{mostChosenLabel}</span>}</div>
                                            : <div>{fieldsData[item.label.toLowerCase()]['typeVal']}{mostChosenLabel &&
                                                <span className="most-chosen">{mostChosenLabel}</span>}</div>
                                        : "Selecteer een optie")
                        ) : (
                            customKey === 0
                                ? (fieldDataMotorToCheck
                                    ? <div>{fieldDataMotorToCheck['typeVal']}{mostChosenLabel &&
                                        <span className="most-chosen">{mostChosenLabel}</span>}</div>
                                    : "Selecteer een optie")
                                : customKey === 1
                                    ? (fieldDataZenderToCheck
                                        ? <div>{fieldDataZenderToCheck['typeVal']}{mostChosenLabel &&
                                            <span className="most-chosen">{mostChosenLabel}</span>}</div>
                                        : "Selecteer een optie")
                                    : "Selecteer een optie"
                        )}
                    </div>
                    <ul className={localToggles[customKey] ? "select-items --open" : "select-items --closed"}>
                        {data.map((option, optionIndex) => {
                            let optionChoiceKey = item["form_fields"][customKey]["form_field"]["options_dropdown"][optionIndex]['choice_key'];

                            if (fieldsData['type']['typeKey'] === "zonnescherm") {
                                const isPremium = fieldsData['uitvoering']['typeKey'] === "premium";

                                // Filter options based on is_premium
                                const filteredOptions = item["form_fields"][customKey]["form_field"]["options_dropdown"].filter(dropdownOption => {
                                    // Assume the dropdownOption contains is_premium_part
                                    return dropdownOption['is_premium_part'] === isPremium;
                                });

                                // Update optionChoiceKey based on the filtered options
                                if (filteredOptions.length > 0) {
                                    optionChoiceKey = filteredOptions[optionIndex % filteredOptions.length]['choice_key'];
                                } else {
                                    optionChoiceKey = null; // or handle the case where no options match
                                }
                            }
                            return (
                                <li data-post-id={option.id} key={dataKey + "-" + optionIndex + "-optionKey"}
                                    className={`input-radio ${disabledEngineOptions.includes(declareLabelKey(option['post_title'])) ? 'disable-conditional-logic' : null}`}>
                                    <input
                                        className={optionChoiceKey}
                                        type={"radio"}
                                        id={dataKey + "-" + optionIndex + "-optionKey"} // Unique id for the input
                                        name={
                                            fieldsData['type']['typeKey'] === "zonnescherm" && customKey === 0
                                                ? item.label.toLowerCase() + "-motor"
                                                : fieldsData['type']['typeKey'] === "zonnescherm" && customKey === 1
                                                    ? item.label.toLowerCase() + "-zender"
                                                    :  customKey === 1
                                                        ? item.label.toLowerCase() + "-motor"
                                                        : customKey === 2
                                                            ? item.label.toLowerCase() + "-zender"
                                                            : item.label.toLowerCase()
                                        }
                                        onChange={(e) => {
                                            handleInputInteraction(e.target, customKey, item, optionIndex, optionChoiceKey, setMostChosenLabel);
                                        }}
                                        value={option.acf.label}
                                        data-price={option.acf.price}
                                        disabled={disabledEngineOptions.includes(declareLabelKey(option['post_title']))}
                                    />
                                    <label htmlFor={dataKey + "-" + optionIndex + "-optionKey"}
                                           className={disabledEngineOptions.includes(declareLabelKey(option['post_title'])) ? 'disable-conditional-logic' : null}>
                                        {option.acf.label}
                                        {item["form_fields"][customKey]["form_field"]["options_dropdown"][optionIndex]["most_chosen"] && (
                                            <>
                                            <span
                                                className="most-chosen">{item["form_fields"][customKey]["form_field"]["options_dropdown"][optionIndex]["most_chosen_label"]}</span>
                                            </>
                                        )}
                                        {/* span is price per engine if neccesary. /*/}
                                        {/*<span>{!!option.acf.price && !isNaN(option.acf.price) && formatPriceLabel(option.acf.price)}</span>*/}
                                    </label>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </>
        );
    }

    const DimensionDropBox = () => {
        const dimensionData = priceData['price_table'][0].table.map(item => item.height);

        useEffect(() => {
            // Filter dimensionData based on shouldBeSkipped logic
            const filteredDimensionData = dimensionData.filter(option => {
                const widthRounded = roundUpWidth(fieldsData['afmeting-breedte']);
                const shouldBeSkipped = !!missingPriceData &&
                    ((parseInt(fieldsData['afmeting-breedte'], 10) < 3500 && parseInt(missingPriceData[0].height, 10) === parseInt(option, 10)) ||
                        (parseInt(fieldsData['afmeting-breedte'], 10) >= 3500 && parseInt(missingPriceData[0].height, 10) === parseInt(option, 10)) && missingPriceData[0][widthRounded] === "0");

                return !shouldBeSkipped;
            });

            // If there's exactly one option after filtering, set it as the default value
            if (filteredDimensionData.length === 1) {
                const singleOptionValue = filteredDimensionData[0];
                 // `dataKey` represents the field name
                if(fieldsData[dataKey] !== singleOptionValue) {
                    setFieldsData({
                        ...fieldsData,
                        [dataKey]: singleOptionValue
                    });
                }
            }
        }, [dimensionData, fieldsData, missingPriceData]);

        const roundUpWidth = (v) => {
            // Convert to integer for safety
            let value = parseInt(v, 10);

            // Calculate the nearest lower multiple of 500 and return it as a string
            let base = Math.floor(value / 500) * 500;

            return base.toString(); // Convert to string and return
        };

        return (
            <>
                {!!dimensionData && dimensionData.length > 0 && (
                    <div className="select-box">
                        <div
                            ref={selectRef}
                            className="select-trigger custom-padding"
                            onClick={() => setToggle(customKey, !localToggles[customKey])}
                        >
                            {!!fieldsData[dataKey] ? (fieldsData[dataKey] + " mm") : "Selecteer een optie"}
                        </div>
                        <ul className={localToggles[customKey] ? "select-items custom-top --open" : "select-items --closed"}>
                            {dimensionData.map((option, optionIndex) => {
                                const widthRounded = roundUpWidth(fieldsData['afmeting-breedte']);
                                // Check if this option should be skipped
                                // This is for now hardcoded: parseInt(fieldsData['afmeting-breedte']) < 3500, value might be 3540 but not sure for now.
                                // tot de max gaan, dus als waarde 3400 is moet het naar 3000.
                                const shouldBeSkipped = !!missingPriceData &&
                                    ((parseInt(fieldsData['afmeting-breedte'], 10) < 3500 && parseInt(missingPriceData[0].height, 10) === parseInt(option, 10)) ||
                                        (parseInt(fieldsData['afmeting-breedte'], 10) >= 3500 && parseInt(missingPriceData[0].height, 10) === parseInt(option, 10)) && missingPriceData[0][widthRounded] === "0");
                                if (shouldBeSkipped) {
                                    return null;
                                }
                                return (
                                    <li key={dataKey + "-" + optionIndex + "-optionKey"}
                                        className={`input-radio custom-select-dimension ${!!item["form_fields"][0]["form_field"]["options_dropdown"][optionIndex] &&
                                            item["form_fields"][0]["form_field"]["options_dropdown"][optionIndex]["apply_conditional_logic"] || ""
                                        }`}>
                                        <input
                                            type="radio"
                                            id={dataKey + "-" + optionIndex + "-optionKey"}
                                            name={dataKey}
                                            onChange={(e) => {
                                                setToggle(customKey, false);
                                                handleInputInteraction(e, typeKey);
                                            }}
                                            value={option}
                                            disabled={shouldBeSkipped}
                                        />
                                        <label htmlFor={dataKey + "-" + optionIndex + "-optionKey"}>
                                            {option + " mm"}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>

                    </div>
                )}
            </>
        );
    }

    return (
        <>
            {boxForStep === "color" && <ColorSelectBox/>}
            {boxForStep === "engine" && <EngineSelectBox/>}
            {boxForStep === "openness" && <OpennessBox/>}
            {boxForStep === "dimensionDrop" && <DimensionDropBox/>}
        </>
    );
}
