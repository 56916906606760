import React, {useState, useEffect, useRef} from "react";
import '../../../scss/QuotationFolder.scss'
import '../../../scss/loading.scss'
import {
    addSessionIDToLocalStorage, addToDataLayer, applyAllDataInBatch,
    basicFetchRequest,
    capitalizeText,
    fetchAllDataInBatchFromRESTAPI,
    setCorrectOptionsMeasurementFields,
    replaceDashToWhitespace,
    mapCartData,
    setLocalStorageQuotationFolder,
    triggerAccordion, handleImageCrops
} from "../../functions/functions.jsx";
import {
    formatPriceLabel,
    calculateTotalPrice,
    handleInitialPriceOnFieldDataChange
} from "../../functions/price-functions.jsx";
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone, faPenToSquare, faAngleDown, faTrash, faFileInvoice, faShareNodes, faArrowRight} from "@fortawesome/pro-regular-svg-icons";
import {useGlobalState} from "../../GlobalContext.jsx";
import Header from "../../header/Header.jsx";
import {ThankYouPage} from "./ThankYouPage.jsx";
import { findImage, cropAndSetImage, findSelectedConfigurator } from "../../functions/functions.jsx";
import apiFetch from "@wordpress/api-fetch";
import Content from "../content/Content.jsx";
import InfoPopup from "../../popups/InfoPopup.jsx";
import GravityForm from "../../forms/gravity-forms/index.jsx";
import parse from "html-react-parser";
import SHA256 from "crypto-js/sha256.js";
import App from "../../../App.jsx";
import {faXmark} from "@fortawesome/pro-solid-svg-icons";
import Footer from "../../footer/Footer.jsx";
import { useLocation } from 'react-router-dom';
import {createBrowserHistory} from "history";

export default function QuotationFolder({ hubspotUrl, preAllConfigurators, preDesiredOrderStatus, preExtraPriceRules, preHeaderData, preStartContentData }) {
    const {quotationFolder, headerData, selectedConfigurator, setHeaderData, allConfigurators, setConfiguratorData, setColorData, setEngineData, setSelectedConfigurator, setPriceData, setFieldsData, setPriceFromFields, editProduct, setEditedProduct, setQuotationFolder, setAllConfigurators, completeQuotationFolder, setStatusQuotationFolder, completeQuotation, setCompleteQuotation, startContentData, setStartContentData, configuratorHubspotUrl, setConfiguratorHubspotUrl, setExtraPriceRules } = useGlobalState();
    const [cartData, setCartData] = useState(quotationFolder);
    const [oldCartData, setOldCartData] = useState({});

    const [croppedImages, setCroppedImages] = useState([]);
    const [quotationContentData, setQuotationContentData] = useState([]);
    const [quotationCompleteContentData, setQuotationCompleteContentData] = useState([]);
    const [navigateEditLink, setNavigateEditLink] = useState("");
    const [shareLink, setShareLink] = useState("");
    const [popupTrigger, setPopupTrigger] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [fullLoadedForm, setFullLoadedForm] = useState(false);
    const [postalCodeValue, setPostalCodeValue] = useState("");
    const [houseNumberValue, setHouseNumberValue] = useState("");
    const [formValues, setFormValues] = useState({});
    const [createdQuotationID, setCreatedQuotationID] = useState(0);
    const [createdQuotationData, setCreatedQuotationData] = useState({});
    const [createdQuotationDate, setCreatedQuotationDate] = useState(0);
    const [hubspotUtkCookieValue, setHubspotUtkCookieValue] = useState('');


    const useCustomNavigate = () => {
        const navigate = useNavigate();
        return (to, { state, ...options } = {}) => {
            navigate(to, { state: { ...state, fromNavigate: true }, ...options });
        };
    };

    const navigate = useCustomNavigate();
    const gravityFormRef = useRef();
    let configuratorOrder = [];
    const history = createBrowserHistory();
    const postalField = document.querySelector(".postal-field");
    const houseNumberField = document.querySelector(".house-number-field");


    useEffect(() => {
        window.scrollTo(0,0)

        const getCookie = (name) => {
            const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) return match[2];
        };

        const hubspotutk = getCookie('hubspotutk');
        if (hubspotutk) {
            setHubspotUtkCookieValue(hubspotutk);
        } else {
            console.error('hubspotutk cookie not found.');
        }
    }, []);

    useEffect(() => {
        if(!!hubspotUrl) {
            setConfiguratorHubspotUrl(hubspotUrl);
        }
    }, [hubspotUrl]);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if(!!cartData && Object.keys(cartData).length > 0) {
            // Add data to DataLayer - START
            // Create layerObject
            const layerData = {
                currency: "EUR",
                items: [

                ]
            };

            let totalPriceAllItems = 0;
            // Loop through the cartData and gather correct data for dataLayer
            for (const [key, value] of Object.entries(cartData)) {
                const priceKeys = Object.keys(value.data.allPrices);
                const totalPrice = priceKeys.reduce((acc, key) => acc + value.data.allPrices[key], 0);
                totalPriceAllItems += totalPrice;


                let correctColorKey;
                let correctColorVal;

                if(value['kleuren-doek-keuze']) {
                    correctColorKey = "kleur_doek";
                    correctColorVal = "kleuren-doek-keuze";
                } else if (value['kleuren-omkasting-keuze']) {
                    correctColorKey = "kleur_lamellen";
                    correctColorVal = "kleuren-lamellen-keuze";
                }

                const itemData = {
                    item_name: value.type ? value.type.typeKey : (value.soort ? value.soort : ''),
                    plaatsing: value.plaatsing,
                    afwerking: value.afwerking,
                    hoogte_breedte: [value['afmeting-hoogte'], value['afmeting-breedte']],
                    kleur_omkasting: value['kleuren-omkasting-keuze'],
                    [correctColorKey]: value[correctColorVal],
                    bediening: value.bediening,
                    montage: value.montage,
                    price: totalPrice
                };

                if(!!value.kastmaat) {
                    itemData.kastmaat = value.kastmaat;
                }

                if(!!value.kast_type) {
                    itemData.kast_type = value['kast_type'];
                }

                layerData.items.push(itemData);
            }
            layerData.value = totalPriceAllItems;

            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if(!!layerData) {
                addToDataLayer("view_cart", layerData,"quote_folder");
            }
            // Add data to DataLayer - END
        }
    }, []);

    /* Set correct address based on postalcode and house number (NETHERLANDS ONLY) */
    useEffect(() => {
        if(fullLoadedForm && !!postalField && !!houseNumberField) {
            postalField.addEventListener("focusout", (e) => {
                if(!!e.target.value) setPostalCodeValue(e.target.value)
            });

            houseNumberField.addEventListener("focusout", (e) => {
                if(!!e.target.value) setHouseNumberValue(e.target.value)
            });
        }
    },[fullLoadedForm, postalField, houseNumberField]);

    useEffect(() => {
        if(headerData['postalCodeAPIActive'] && !!houseNumberValue && !!postalCodeValue) {
            const fetchSlug = `https://postcode.tech/api/v1/postcode?postcode=${postalCodeValue}&number=${houseNumberValue}`;
            const token = "39ef0687-af6b-4191-8106-196c08d8e4e9";

            fetch(fetchSlug, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => response.json())
                .then(data => {
                    if(!!data) {
                        const street = data.street;
                        const city = data.city;
                        const addressField = document.querySelector(".address-field input");
                        const cityField = document.querySelector(".city-field input");

                        const addressIndex = parseInt(addressField.name.split("_")[1]);
                        const cityIndex = parseInt(cityField.name.split("_")[1]);

                        if(typeof street !== "undefined" && typeof city !== "undefined") {
                            // Set values in HTML
                            addressField.setAttribute("value", street);
                            cityField.setAttribute("value", city);

                            // set values in GF state
                            formValues[addressIndex].value = street;
                            formValues[cityIndex].value = city;
                        } else {
                            alert("Geen adres gevonden voor deze postcode en huisnummer combinatie.");
                        }
                    }

                })
                .catch(error => console.error('Error:', error));
        }
    }, [houseNumberValue, postalCodeValue]);

    useEffect(() => {
        // Check if there are parameters
        const hasParams = new URLSearchParams(window.location.search).has('configurator');

        if (hasParams) {
            // Remove URL parameters
            const newUrl = new URL(window.location.href);
            newUrl.search = '';
            window.history.pushState(null, null, newUrl.href);
        }
    }, []);

    useEffect(() => {
        fetchQuotationContentDataFromRestAPI().then((r) => {
            if(!!r.acf) {
                setQuotationContentData({0: r.acf});
            }
        })

        fetchQuotationCompleteContentDataFromRestAPI().then((r) => {
            if(!!r.acf) {
                setQuotationCompleteContentData({0: r.acf});
            }
        })

        const handleApiCaching = async (reorderedObject) => {
            // Check if cached data exists
            const cachedData = localStorage.getItem('cachedConfiguratorData');

            if (cachedData) {
                const cachedDataParsed = JSON.parse(cachedData);
                const cachedChecksum = cachedDataParsed.checksum;

                // Calculate SHA-256 checksum for fetched data
                const fetchedChecksum = SHA256(JSON.stringify(reorderedObject)).toString();

                if (cachedChecksum === fetchedChecksum) {
                    // Data has not changed, use the cached data
                    setAllConfigurators(cachedDataParsed.data);
                    setHeaderData(cachedDataParsed.headerData || {});
                    setStartContentData(cachedDataParsed.startContentData || {});
                    return true;
                }
            }

            return false;
        };

        const fetchDataAndApply = async () => {
            try {
                const pathName = window.location.pathname.split('/');
                const loadedConfigurator = pathName.pop();

                if(!!preAllConfigurators && !!preExtraPriceRules && !!preHeaderData && !!preStartContentData) {
                    setAllConfigurators(preAllConfigurators);
                    setExtraPriceRules(preExtraPriceRules);
                    setHeaderData(preHeaderData);
                    setStartContentData(preStartContentData);
                }

                if(preAllConfigurators.length <= 0) return navigate(`/${!!configuratorHubspotUrl ? configuratorHubspotUrl : hubspotUrl}`);

                handleApiCaching(preAllConfigurators).then((isCached) => {
                    if (isCached) {
                        addSessionIDToLocalStorage();
                    }
                });

            } catch (error) {
                console.error('Error processing data:', error);
            }
        };

        fetchDataAndApply();
    },[]);

    useEffect(() => {
        if(Object.keys(cartData).length > 0 && !completeQuotationFolder) {
            setLocalStorageQuotationFolder(cartData);
            handleImageCrops(cartData, setCroppedImages);
        } else if (Object.keys(cartData).length <= 0 && !completeQuotationFolder && !completeQuotation) {
            const localStorageQuotationFolder = JSON.parse(localStorage.getItem("data_quotation"));
            if(!!localStorageQuotationFolder && Object.keys(localStorageQuotationFolder.quotationFolder).length > 0) {
                setQuotationFolder(localStorageQuotationFolder.quotationFolder);
                setCartData(localStorageQuotationFolder.quotationFolder);
            }
        }
        handleTotalPrice();
    }, [cartData, completeQuotationFolder]);

    useEffect(() => {
        if(!!editProduct) {
            navigate(`/${navigateEditLink}`)
        }
    }, [editProduct]);

    useEffect(() => {
        // Function to handle form title, remove 'Offertemap - '.
        const observer = new MutationObserver(() => {
            const loadedGfForm = gravityFormRef.current?.querySelector("h3.form-title");
            if (loadedGfForm) {
                const formTitle = gravityFormRef.current.querySelector("h3.form-title");
                const newFormTitleText = formTitle.textContent.split("-")[1];

                if(newFormTitleText) {
                    newFormTitleText.trim();
                    formTitle.innerText = newFormTitleText;
                    observer.disconnect();
                }
            }
        });
        observer.observe(document.body, { childList: true, subtree: true });
    }, [gravityFormRef, completeQuotationFolder]);

    useEffect(() => {
        const dataQuotation = JSON.parse(localStorage.getItem("data_quotation"));
        const isUserNavigation = location.state?.fromNavigate !== true;

        if(isUserNavigation && dataQuotation) {
            const isAanvragenPath = history.location.pathname === `/${!!configuratorHubspotUrl ? configuratorHubspotUrl : hubspotUrl}/aanvragen`
            const isOffertemapPath = history.location.pathname === `/${!!configuratorHubspotUrl ? configuratorHubspotUrl : hubspotUrl}/offertemap`
            const hasQuotationFolder = Object.keys(dataQuotation.quotationFolder).length > 0;

            if(isAanvragenPath && !completeQuotationFolder) {
                setStatusQuotationFolder(hasQuotationFolder);
            } else if(isOffertemapPath) {
                setStatusQuotationFolder(false);
            }
        } else if(!dataQuotation) {
            navigate(`/${!!configuratorHubspotUrl ? configuratorHubspotUrl : hubspotUrl}/offertemap`);
        }
    }, [history.location.pathname]);

    // UseEffect to handle purchase dataLayer.
    useEffect(() => {
        // @TODO: Add transacton_IDto the dataLayer
        if(!!oldCartData && Object.keys(oldCartData).length > 0 && completeQuotation) {
            // Add data to DataLayer - START
            // Create layerObject
            // Create an object to hold data for the dataLayer
            const layerData = {
                currency: "EUR",
                items: []
            };

            let totalPriceAllItems = 0;

            // Loop through the cartData to gather data for the dataLayer
            for (const [key, value] of Object.entries(oldCartData)) {
                const priceKeys = Object.keys(value.data.allPrices);
                const totalPrice = priceKeys.reduce((acc, key) => acc + value.data.allPrices[key], 0);
                totalPriceAllItems += totalPrice;

                // Prepare data for each item
                const itemData = {
                    item_name: value.type.typeKey,
                    plaatsing: value.plaatsing,
                    afwerking: value.afwerking,
                    hoogte_breedte: [value['afmeting-hoogte'], value['afmeting-breedte']],
                    kleur_omkasting: value['kleuren-omkasting-keuze'],
                    kleur_lamellen: value['kleuren-lamellen-keuze'],
                    bediening: value.bediening,
                    montage: value.montage,
                    kast_type: value['kast_type'],
                    kastmaat: value.kastmaat,
                    price: totalPrice,
                    quantity: value.data.amount
                };

                // Add item data to the layerData object
                layerData.items.push(itemData);
            }

            // Add total price to the layerData object
            layerData.value = totalPriceAllItems;
            layerData.tax = Math.round(0.21 * totalPriceAllItems);
            layerData.currency = "EUR";
            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if(!!layerData) {
                addToDataLayer("purchase", layerData, "");
            }
            // Add data to DataLayer - END
        }
    }, [completeQuotation, oldCartData]);

    const shareCurrentConfiguration = () => {
        if(!!cartData && Object.keys(cartData).length > 0) {
            // Add data to DataLayer - START
            // Create layerObject
            // Create an object to hold data for the dataLayer
            const layerData = {
                currency: "EUR",
                promotion_name: "share_quote",
                items: []
            };

            let totalPriceAllItems = 0;

            // Loop through the cartData to gather data for the dataLayer
            for (const [key, value] of Object.entries(cartData)) {
                const priceKeys = Object.keys(value.data.allPrices);
                const totalPrice = priceKeys.reduce((acc, key) => acc + value.data.allPrices[key], 0);
                totalPriceAllItems += totalPrice;

                // Prepare data for each item
                const itemData = {
                    item_name: value.type.typeKey,
                    plaatsing: value.plaatsing,
                    afwerking: value.afwerking,
                    hoogte_breedte: [value['afmeting-hoogte'], value['afmeting-breedte']],
                    kleur_omkasting: value['kleuren-omkasting-keuze'],
                    kleur_lamellen: value['kleuren-lamellen-keuze'],
                    bediening: value.bediening,
                    montage: value.montage,
                    kast_type: value['kast_type'],
                    kastmaat: value.kastmaat,
                    price: totalPrice,
                    quantity: value.data.amount
                };

                // Add item data to the layerData object
                layerData.items.push(itemData);
            }

            // Add total price to the layerData object
            layerData.value = totalPriceAllItems;

            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if(!!layerData) {
                addToDataLayer("select_promotion", layerData, "");
            }
            // Add data to DataLayer - END
        }


        const localStorageDataQuotation = JSON.parse(localStorage.getItem("data_quotation"));
        if(localStorageDataQuotation) {
            let url;
            // if should be unneccesary since main page is /configurator in dev
            url = new URL(window.location.origin);
            url.searchParams.set('quotationFolder', JSON.stringify(localStorageDataQuotation.quotationFolder));
            saveURLToDatabase(url.href).then((r) => {
                setShareLink(r['short-url']);
                setPopupTrigger(true);
            });
        }
    };

    const saveURLToDatabase = async (url) => {
        const fetchSlug = "degalux-conf/v1/share/quotation";
        const sessionID = localStorage.getItem('sessionID');

        try {
            const response = await apiFetch({
                path: basicFetchRequest(fetchSlug),
                method: 'POST',
                headers: {
                    'Http-X-Wp-Api-Token': headerData['REST']['restApi'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title_id: sessionID, data: url })
            });

            return response;

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    };

    const fetchQuotationContentDataFromRestAPI = async () => {
        const fetchSlug = "wp/v2/pages/1152"

        try {
            const response = await apiFetch({
                path: basicFetchRequest(fetchSlug),
                method: 'GET',
                headers: {
                    // 'Authorization': `Basic ${base64Credentials}`,
                    'Content-Type': 'application/json',
                },
            });

            if(!!response) {
                return response;
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error; // Rethrow the error to handle it elsewhere if needed
        }
    }

    const fetchQuotationCompleteContentDataFromRestAPI = async () => {
        let fetchSlug = "wp/v2/pages/1874"

        try {
            const response = await apiFetch({
                path: basicFetchRequest(fetchSlug),
                method: 'GET',
                headers: {
                    // 'Authorization': `Basic ${base64Credentials}`,
                    'Content-Type': 'application/json',
                },
            });

            if(!!response) {
                return response;
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error; // Rethrow the error to handle it elsewhere if needed
        }
    }

    const handleAmountChange = (type, productIndex) => {
        if(!!cartData && Object.keys(cartData).length > 0) {
            // Add data to DataLayer - START
            // Create layerObject
            const layerData = {
                currency: "EUR",
                items: [

                ]
            };

            let totalPriceAllItems = 0;
            // Loop through the cartData and gather correct data for dataLayer
            for (const [key, value] of Object.entries(cartData)) {
                const priceKeys = Object.keys(value.data.allPrices);
                const totalPrice = priceKeys.reduce((acc, key) => acc + value.data.allPrices[key], 0);
                totalPriceAllItems += totalPrice;

                const itemData = {
                    item_name: value.type.typeKey,
                    plaatsing: value.plaatsing,
                    afwerking: value.afwerking,
                    hoogte_breedte: [value['afmeting-hoogte'], value['afmeting-breedte']],
                    kleur_omkasting: value['kleuren-omkasting-keuze'],
                    kleur_lamellen: value['kleuren-lamellen-keuze'],
                    bediening: value.bediening,
                    montage: value.montage,
                    kast_type: value['kast_type'],
                    kastmaat: value.kastmaat,
                    price: totalPrice,
                    quantity: value.data.amount
                };

                layerData.items.push(itemData);
            }
            layerData.value = totalPriceAllItems;

            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if(!!layerData) {
                let event = type === "minus" ? "remove_from_cart" : "add_to_cart";
                let source = type === "minus" ? "quote_folder_remove" : "quote_folder_add";
                addToDataLayer(event, layerData,source);
            }
            // Add data to DataLayer - END
        }

        setCartData(prevCartData => {
            const updatedCartData = { ...prevCartData };

            const activeItemKey = Object.keys(updatedCartData)[productIndex];
            if (activeItemKey) {
                const activeItem = { ...updatedCartData[activeItemKey] };
                const newAmount = type === "minus" ? activeItem.data.amount - 1 : activeItem.data.amount + 1;

                if (newAmount > 0) {
                    // Create a new object to avoid direct modification
                    const updatedItem = { ...activeItem, data: { ...activeItem.data, amount: newAmount } };
                    updatedCartData[activeItemKey] = updatedItem;
                }
            }

            return { ...updatedCartData };
        });
    };



    const handleDeleteItem = (productIndex) => {
        if(!!cartData && Object.keys(cartData).length > 0) {
            // Add data to DataLayer - START
            // Create layerObject
            const layerData = {
                currency: "EUR",
                items: [

                ]
            };

            let totalPriceAllItems = 0;
            // Loop through the cartData and gather correct data for dataLayer
            for (const [key, value] of Object.entries(cartData)) {
                const priceKeys = Object.keys(value.data.allPrices);
                const totalPrice = priceKeys.reduce((acc, key) => acc + value.data.allPrices[key], 0);
                totalPriceAllItems += totalPrice;

                const itemData = {
                    item_name: value.type.typeKey,
                    plaatsing: value.plaatsing,
                    afwerking: value.afwerking,
                    hoogte_breedte: [value['afmeting-hoogte'], value['afmeting-breedte']],
                    kleur_omkasting: value['kleuren-omkasting-keuze'],
                    kleur_lamellen: value['kleuren-lamellen-keuze'],
                    bediening: value.bediening,
                    montage: value.montage,
                    kast_type: value['kast_type'],
                    kastmaat: value.kastmaat,
                    price: totalPrice,
                    quantity: value.data.amount
                };

                layerData.items.push(itemData);
            }
            layerData.value = totalPriceAllItems;

            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if(!!layerData) {
                addToDataLayer("remove_from_cart", layerData, "quote_folder_remove");
            }
            // Add data to DataLayer - END
        }

        const keysArray = Object.keys(cartData);

        if (keysArray.length > productIndex) {
            const updatedCartData = { ...cartData };
            delete updatedCartData[keysArray[productIndex]];

            // Reindex keys based on the length of the updated array
            const reindexedCartData = {};
            keysArray.forEach((key, index) => {
                if (index !== productIndex) {
                    const newIndex = index < productIndex ? index : index - 1;
                    reindexedCartData[`${key.split("-")[0]}-${newIndex}`] = updatedCartData[key];
                }
            });

            localStorage.setItem('data_quotation', JSON.stringify({ quotationFolder: reindexedCartData }));
            setCartData(reindexedCartData);
            setQuotationFolder(reindexedCartData);
        }
    };


    const handleEditItem = (productIndex) => {
        if(!!cartData && Object.keys(cartData).length > 0) {
            // Add data to DataLayer - START
            // Create layerObject
            const layerData = {
                currency: "EUR",
                items: [

                ]
            };

            const editedItem = Object.values(cartData)[productIndex]

            const priceKeys = Object.keys(editedItem.data.allPrices);
            const totalPrice = priceKeys.reduce((acc, key) => acc + editedItem.data.allPrices[key], 0);
            layerData.value = totalPrice;

            // Append correct data to layerData based on the fetched data
            const itemData = {
                item_name: !!editedItem.type && !!editedItem.type.typeKey ? capitalizeText(editedItem.type.typeKey) : capitalizeText(selectedConfigurator),
                price: totalPrice,
            }

            layerData.items.push(itemData);

            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if(!!layerData) {
                addToDataLayer("view_item", layerData, "quote_folder_change");
            }
            // Add data to DataLayer - END
        }
        if (!!Object.keys(cartData)[productIndex]) {
            const handleLocalData = () => {
                if (Object.keys(allConfigurators).length === 0) return;

                let cartItem;
                const quotationFolderStorage = JSON.parse(localStorage.getItem('data_quotation'));
                if (quotationFolderStorage) {
                    Object.keys(cartData).forEach((product) => {
                        if (quotationFolderStorage.quotationFolder[Object.keys(cartData)[productIndex]]) {
                            cartItem = quotationFolderStorage.quotationFolder[Object.keys(cartData)[productIndex]];
                        }
                    });
                }
                if (cartItem) {
                    const selectedConfigurator = findSelectedConfigurator(cartItem.data.nameConfigurator, allConfigurators);
                    if (selectedConfigurator) {
                        setConfiguratorData(selectedConfigurator.acf.steps);
                        setPriceData(selectedConfigurator.priceData);
                        setColorData(selectedConfigurator.colorData);
                        setEngineData(selectedConfigurator.engineData);
                        setSelectedConfigurator(selectedConfigurator.title.toLowerCase());
                    }
                }
                const cartDataForFields = {...cartItem};
                delete cartDataForFields.data;

                const cartDataForPrice = {...cartItem.data.allPrices};
                setFieldsData(cartDataForFields);
                setPriceFromFields(cartDataForPrice);
                setEditedProduct(Object.keys(cartData)[productIndex]);
                setNavigateEditLink(`${configuratorHubspotUrl}`+ "/" + cartItem.data.nameConfigurator);
            };
            handleLocalData();

        }
    }
    const handleDuplicateItem = (productIndex) => {
        // Duplicate the item in cartData
        const objectKey = Object.keys(cartData)[productIndex];

        if (!!objectKey) {
            const shallowCartData = { ...cartData };
            const copyItem = shallowCartData[objectKey];

            const keysArray = Object.keys(shallowCartData);
            const lastKeyValue = parseInt(keysArray[keysArray.length - 1].replace(/\D/g, ""));
            const newKey = `${objectKey.split("-")[0]}-${keysArray.length === 1 ? keysArray.length : lastKeyValue + 1}`;

            shallowCartData[newKey] = copyItem;

            // Update the state with the duplicated item included
            if (!!shallowCartData[newKey]) {
                setCartData(shallowCartData);
                setQuotationFolder(shallowCartData);

                // Create an object to hold data for the dataLayer
                const layerData = {
                    currency: "EUR",
                    items: []
                };

                let totalPriceAllItems = 0;

                // Loop through the cartData to gather data for the dataLayer
                for (const [key, value] of Object.entries(shallowCartData)) {
                    const priceKeys = Object.keys(value.data.allPrices);
                    const totalPrice = priceKeys.reduce((acc, key) => acc + value.data.allPrices[key], 0);
                    totalPriceAllItems += totalPrice;

                    // Prepare data for each item
                    const itemData = {
                        item_name: value.type.typeKey,
                        plaatsing: value.plaatsing,
                        afwerking: value.afwerking,
                        hoogte_breedte: [value['afmeting-hoogte'], value['afmeting-breedte']],
                        kleur_omkasting: value['kleuren-omkasting-keuze'],
                        kleur_lamellen: value['kleuren-lamellen-keuze'],
                        bediening: value.bediening,
                        montage: value.montage,
                        kast_type: value['kast_type'],
                        kastmaat: value.kastmaat,
                        price: totalPrice,
                        quantity: value.data.amount
                    };

                    // Add item data to the layerData object
                    layerData.items.push(itemData);
                }

                // Add total price to the layerData object
                layerData.value = totalPriceAllItems;

                // Check if the layerData is not empty and if the page is not localhost
                if (!!layerData) {
                    // Add data to the dataLayer
                    addToDataLayer("add_to_cart", layerData, "quote_folder_duplicate");
                }
            }
        }
    };



    const handleQuotationForm = () => {
        if(!!cartData && Object.keys(cartData).length > 0) {
            // Add data to DataLayer - START
            // Create layerObject
            // Create an object to hold data for the dataLayer
            const layerData = {
                currency: "EUR",
                items: []
            };

            let totalPriceAllItems = 0;

            // Loop through the cartData to gather data for the dataLayer
            for (const [key, value] of Object.entries(cartData)) {
                const priceKeys = Object.keys(value.data.allPrices);
                const totalPrice = priceKeys.reduce((acc, key) => acc + value.data.allPrices[key], 0);
                totalPriceAllItems += totalPrice;

                // Prepare data for each item
                const itemData = {
                    item_name: value.type.typeKey,
                    plaatsing: value.plaatsing,
                    afwerking: value.afwerking,
                    hoogte_breedte: [value['afmeting-hoogte'], value['afmeting-breedte']],
                    kleur_omkasting: value['kleuren-omkasting-keuze'],
                    kleur_lamellen: value['kleuren-lamellen-keuze'],
                    bediening: value.bediening,
                    montage: value.montage,
                    kast_type: value['kast_type'],
                    kastmaat: value.kastmaat,
                    price: totalPrice,
                    quantity: value.data.amount
                };

                // Add item data to the layerData object
                layerData.items.push(itemData);
            }

            // Add total price to the layerData object
            layerData.value = totalPriceAllItems;

            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if(!!layerData) {
                addToDataLayer("begin_checkout", layerData, "");
            }
            // Add data to DataLayer - END
        }

        if(!!Object.keys(cartData).length > 0) {
            setStatusQuotationFolder(true)
            navigate(`/${configuratorHubspotUrl}/aanvragen`)
        }
    }

    const handleSuccessfulSubmit = async (data) => {
        if (!!data.is_valid && !completeQuotation) {
            setCompleteQuotation(true);
            setQuotationFolder({});
            setOldCartData(cartData);
            setCartData({});
            localStorage.setItem('data_quotation', JSON.stringify({quotationFolder: {}}));

            const regex = /created_quotation_id=(\d+)/;  // Regular expression to match 'id=' followed by digits
            const match = data.confirmation_message.match(regex);

            if (match) {
                const id = match[1];  // Extracted id value
                setCreatedQuotationID(id);  // Set the extracted id in state or variable

                // Generate a unique token
                const token = Math.random().toString(36).substring(2);

                let host = window.location.host !== "localhost:3030" ? window.location.host : "localhost:8000";

                if (host === "degalux.com") {
                    host = "aanvragen.degalux.com";
                }

                let apiUrl = `${window.location.protocol}//${host}/wp-json/degalux-conf/v1/quotation_data/${id}`;

                let headers = {
                    'Content-Type': 'application/json'
                };

                if (window.location.host !== "localhost:3030") {
                    headers['Http-X-Wp-Api-Token'] = headerData['REST']['restApi'];
                }

                fetch(apiUrl, {
                    headers: headers
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(async (quotationData) => {
                        const date = new Date(quotationData['date_created']);
                        const formattedDate = date.toLocaleDateString('nl-NL', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                        });

                        const payload = {
                            quotation_id: id,
                            token: token,
                            data: {
                                createdQuotationData: quotationData,
                                createdQuotationDate: formattedDate,
                                oldCartData: cartData,
                                selectedConfigurator: selectedConfigurator,
                                totalPrice: totalPrice
                            }
                        };

                        const storeApiUrl = `${window.location.protocol}//${host}/wp-json/degalux-conf/v1/store_quotation_data`;

                        const storeResponse = await fetch(storeApiUrl, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(payload)
                        });

                        if (!storeResponse.ok) {
                            throw new Error('Failed to store quotation data');
                        }

                        const { unique_id } = await storeResponse.json();

                        // Redirect to thank you page with the unique ID and token
                        window.location.href = headerData['hubspotThankYouURI'] + `?typc_id=${unique_id}&typc_tkn=${token}`;
                    })
                    .catch(error => {
                        console.error('Error fetching or storing quotation data:', error);
                    });
            } else {
                console.error('ID not found in confirmation_message');
            }
        }
    };


    const handleTotalPrice = () => {
        let totalPriceLoop = 0;
        if(!!cartData && Object.keys(cartData).length > 0) {
            for (const [key, value] of Object.entries(cartData)) {
                Object.values(value.data.allPrices).forEach((price) => {
                    totalPriceLoop += price * value.data.amount;
                });
            }
            if(!!totalPriceLoop && totalPriceLoop !== 0) {
                setTotalPrice(totalPriceLoop);
            }
        }
    }

    const handleConfiguratorData = (data) => {
        if (!!data) {
            setConfiguratorData(data.acf.steps);
            setPriceData(data.priceData);
            setColorData(data.colorData);
            setEngineData(data.engineData);
            setSelectedConfigurator(data.title.toLowerCase());

            // Remove old fieldsData to prevent configurator from breaking
            setFieldsData({});
            navigate(`/${configuratorHubspotUrl}/${data.title.toLowerCase()}`);
        }
    };

    useEffect(() => {
        if(!!createdQuotationData) {
            const date = new Date(createdQuotationData['date_created']);
            const formattedDate = date.toLocaleDateString('nl-NL', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
            });
            setCreatedQuotationDate(formattedDate);
        }
    }, [createdQuotationData]);

    return (
        <>
            {!!Object.keys(quotationContentData).length > 0 && Object.values(allConfigurators).length > 0 && (
                <div>
                    {!completeQuotation && (
                        <>
                            <Header data={headerData} setHeaderData={setHeaderData} />
                            <InfoPopup link={shareLink} trigger={popupTrigger} set={setPopupTrigger} setTextParse={false} customWidth={'650px'} shareByEmail={true}/>
                        </>
                    )}

                    <div className="main__checkout" style={completeQuotation ? {gridTemplateColumns: "repeat(4,1fr)", display: "grid", minHeight: "100vh", background: "#F5F6F7"} : {}}>
                        {Object.keys(cartData).length <= 0 && (
                            <>
                                {!completeQuotation && Object.values(allConfigurators).length > 0 && (
                                    <>
                                        <div />
                                        <h2 style={{textAlign: "center"}}>Uw offertemap is leeg</h2>
                                        {(Object.keys(allConfigurators).length > 0 && !window.location.search.includes("share=") && (
                                            <>
                                                <div className="main__start">
                                                    <div className="full select-product">
                                                        {Object.values(allConfigurators).length > 0 && Object.values(startContentData).length > 0 && (
                                                            <>
                                                                <div className="select-product__title">
                                                                    <h2> {startContentData.title}</h2>
                                                                    <p> {startContentData.description} </p>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="all-products">
                                                            {Object.values(allConfigurators).length > 0 &&
                                                                Object.values(allConfigurators).map((configurator, index) => (
                                                                    <div
                                                                        className={`product__${configurator.title} product-card`}
                                                                        key={configurator.title + index}
                                                                        onClick={() => handleConfiguratorData(configurator)}
                                                                    >
                                                                        <div className="entry-image animation">
                                                                            <div
                                                                                className="image animation"
                                                                                style={
                                                                                    configurator.title === "Rolluik"
                                                                                        ? {
                                                                                            background: `url(https://aanvragen.degalux.com/wp-content/uploads/2023/10/rolluik_anim.png) no-repeat left center`,
                                                                                        }
                                                                                        : configurator.title === "Zonnescherm"
                                                                                            ? {
                                                                                                background: `url(https://aanvragen.degalux.com/wp-content/uploads/2023/11/zonnescherm-anim.png) no-repeat -78px 0% / 64036px`,
                                                                                            }
                                                                                            : configurator.title === "Screen"
                                                                                                ? {
                                                                                                    background: `url(https://aanvragen.degalux.com/wp-content/uploads/2023/10/screen-sprite.png) no-repeat 0% 0% / 36089px`,
                                                                                                }
                                                                                                : null
                                                                                }
                                                                            />
                                                                            {configurator.title === "Rolluik" && !!configurator.acf['start_fields'] && (
                                                                                <>
                                                                                    <div className="start-price">
                                                                                        <span>Vanaf</span>
                                                                                        {configurator.acf['start_fields']['start_price']}
                                                                                    </div>

                                                                                    <div className={`usp ${configurator.title}`}>
                                                                                        <h1 className="card-title">{configurator.title}</h1>
                                                                                        {parse(configurator.acf['start_fields']['start_usp'])}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            {configurator.title === "Zonnescherm" && !!configurator.acf['start_fields'] && (
                                                                                <>
                                                                                    <div className="start-price">
                                                                                        <span>Vanaf</span>
                                                                                        {configurator.acf['start_fields']['start_price']}
                                                                                    </div>

                                                                                    <div className={`usp ${configurator.title}`}>
                                                                                        <h1 className="card-title">{configurator.title}</h1>
                                                                                        {parse(configurator.acf['start_fields']['start_usp'])}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            {configurator.title === "Screen" && !!configurator.acf['start_fields'] && (
                                                                                <>
                                                                                    <div className="start-price">
                                                                                        <span>Vanaf</span>
                                                                                        {configurator.acf['start_fields']['start_price']}
                                                                                    </div>

                                                                                    <div className={`usp ${configurator.title}`}>
                                                                                        <h1 className="card-title">{configurator.title}</h1>
                                                                                        {parse(configurator.acf['start_fields']['start_usp'])}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <button
                                                                            className="card-button"
                                                                            onClick={() => handleConfiguratorData(configurator)}
                                                                        >
                                                                            {`${configurator.title} samenstellen`}
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                        {!!cartData && Object.keys(cartData).length > 0 && !!quotationContentData && Object.keys(quotationContentData).length > 0 && (
                            <div className="container">
                                <section className="overview">
                                    {!!cartData && !completeQuotationFolder && Object.values(cartData).map((item, productIndex) => (
                                        <div key={"B" + Object.keys(item).length + productIndex} className="product">
                                            <div className="product-overview">

                                                <div className="image-container">
                                                    {!!croppedImages && croppedImages.length > 0 && croppedImages.map((imageSet, index) => (
                                                        <React.Fragment key={"imgSet" + Object.keys(imageSet).length + index}>
                                                            {productIndex === index && (
                                                                <>
                                                                    <img src={imageSet.innerImage}
                                                                         alt="Binnenwerk"/>
                                                                    <img src={imageSet.outerImage}
                                                                         alt="Buitenkant"/>
                                                                </>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </div>

                                                <div className="product-data">
                                                    <span className="extra-description">Daar is 'ie dan</span>
                                                    <h2 className="product-title">Jouw
                                                        nieuwe {typeof item.type !== "undefined" && !!Object.keys(item.type).length > 0 ? item.type.typeKey : item.data.nameConfigurator}</h2>
                                                    {/*@TODO: Add reference */}
                                                    <div className="configuration-details accordion --collapsed">
                                                        <div className="accordion-wrapper">
                                                            <div>
                                                                {Object.entries(mapCartData(item, selectedConfigurator)).map(([key, value]) => (
                                                                    <React.Fragment key={"mCartData" + key}>
                                                                        {key !== "data" && !!key && !!value && (
                                                                            <div className="detail-container" key={key}>
                                                                                <p className="product-section">
                                                                                    {!key.includes('_') ? replaceDashToWhitespace(capitalizeText(key)) + ":" : replaceDashToWhitespace(capitalizeText(key), true) + ":"}
                                                                                </p>
                                                                                <p className="product-section-value">
                                                                                    {typeof value === 'object' ? value.typeVal : value}
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className="accordion-trigger --small" onClick={(e) => {
                                                        triggerAccordion(e, [
                                                            "Toon meer",
                                                            "Toon minder",
                                                        ])
                                                    }}>
                                                        Toon meer
                                                    </button>
                                                    <div className="product-details-actions">
                                                        {/* // @TODO: Add actions for input & buttons */}
                                                        <div className="amount-select">
                                                            <button
                                                                onClick={(e) => handleAmountChange("minus", productIndex)}>-
                                                            </button>
                                                            {item.data.amount}
                                                            <button
                                                                onClick={(e) => handleAmountChange("plus", productIndex)}>+
                                                            </button>
                                                        </div>
                                                        <div className="button-group">
                                                            <button onClick={() => handleEditItem(productIndex)}
                                                                    className="--small">
                                                                <FontAwesomeIcon icon={faPenToSquare} size="1x"/>
                                                                Wijzig
                                                            </button>
                                                            <button onClick={() => handleDeleteItem(productIndex)}
                                                                    className="--small">
                                                                <FontAwesomeIcon icon={faTrash} size="1x"/>
                                                                Verwijder
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="price">
                                                    <p>
                                                        <span>{formatPriceLabel((calculateTotalPrice(item.data.allPrices, item.data.amount) * item.data.amount))}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="product-actions">
                                                <div className="action-text">
                                                    <b>Nog eentje?</b> Maak een kopie van jouw configuratie en pas'm
                                                    daarna
                                                    eenvoudig aan.
                                                </div>
                                                <button onClick={() => handleDuplicateItem(productIndex)}
                                                        className="--small">
                                                    <FontAwesomeIcon icon={faClone} size="1x"/>
                                                    Dupliceren
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    {completeQuotationFolder && !completeQuotation && !!headerData && (
                                        <div ref={gravityFormRef} className="gf-container">
                                            {/*@TODO/Awin: FORMID moet altijd op 4 staan voor staging of productie*/}
                                            <GravityForm
                                                backendUrl="/?rest_route=/draad/v1/gf/forms"
                                                formID={4}
                                                formValues={formValues}
                                                setFormValues={setFormValues}
                                                onSubmitSuccess={handleSuccessfulSubmit}
                                                populatedFields={{
                                                    data_quotation: JSON.stringify({...cartData}),
                                                    hubspotutk_value: hubspotUtkCookieValue
                                                }}
                                                loadingSpinner={true}
                                                setFullLoadedForm={setFullLoadedForm}
                                            />
                                        </div>
                                    )}
                                </section>
                                <aside className="cart">
                                    <div className="cart-card">
                                        <div className="cHeader">
                                            <h2>Overzicht <span>{`(${Object.keys(cartData).length} artikelen)`}</span>
                                            </h2>

                                        </div>
                                        <div className="cContent">
                                            <div className="order">
                                                {!!cartData && Object.values(cartData).map((item, productIndex) => (
                                                    <React.Fragment key={"A" + Object.keys(item).length + productIndex}>
                                                        <div
                                                             className="product-label">
                                                            <span className="product-title">
                                                                {!!item.type && !!Object.keys(item.type).length > 0 ? item.type.typeVal : capitalizeText(item.data.nameConfigurator)}
                                                            </span>
                                                            <span
                                                                className="product-amount">{item.data.amount + 'x'}
                                                            </span>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div className="footer">
                                                <div className="total-price">
                                                    <span className="price-label">Productprijs</span>
                                                    <span
                                                        className="price">€{formatPriceLabel((totalPrice), false)}</span>
                                                </div>
                                                <div className="caution-text">
                                                    <p>
                                                        <span>Let op:</span> deze prijs is exclusief montagekosten.
                                                        Vraag een
                                                        offerte
                                                        aan voor de prijs inclusief montagekosten.
                                                    </p>
                                                </div>
                                                {!completeQuotation && !completeQuotationFolder && (
                                                    <>
                                                        <button onClick={() => handleQuotationForm()}
                                                                className="rA-first request-action --center">
                                                            <FontAwesomeIcon icon={faFileInvoice} size="1x"/>
                                                            Offerte aanvragen
                                                        </button>
                                                        <button onClick={() => shareCurrentConfiguration()}
                                                                className="request-action --center --tertiary">
                                                            <FontAwesomeIcon icon={faShareNodes} size="1x"/>
                                                            <span>Offertemap delen</span>
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </aside>

                                <section className="floating-nav">
                                    {!completeQuotation && !completeQuotationFolder && (
                                        <>
                                            <button onClick={() => handleQuotationForm()}
                                                    className="rA-first request-action --center">
                                                <FontAwesomeIcon icon={faFileInvoice} size="1x"/>
                                                Offerte aanvragen
                                            </button>
                                            <button onClick={() => shareCurrentConfiguration()}
                                                    className="request-action --center --tertiary">
                                                <FontAwesomeIcon icon={faShareNodes} size="1x"/>
                                                <span>Offertemap delen</span>
                                            </button>
                                        </>
                                    )}
                                </section>

                                {!completeQuotationFolder && (
                                    <div>
                                        <h2>{!!quotationContentData[0]['extra_prods_title'] ? quotationContentData[0]['extra_prods_title'] : "Wil je nog een een product samenstellen?"}</h2>
                                        <div className="add-prod-container">
                                            {!completeQuotation && Object.values(allConfigurators).map((configurator, index) => (
                                                <div
                                                    className="product-card"
                                                    key={configurator.title + index}
                                                    onClick={() => handleConfiguratorData(configurator)}
                                                >
                                                    <div className="quotation-images">
                                                        {configurator.title === "Rolluik" && (
                                                            <img
                                                                src={quotationContentData[0]['rolluik_image']?.link}
                                                                alt="Rolluik"/>
                                                        )}
                                                        {configurator.title === "Zonnescherm" && (
                                                            <img className={configurator.title + '-image'}
                                                                 src={quotationContentData[0]['zonnescherm_image']?.link}
                                                                 alt="Zonnescherm"/>
                                                        )}
                                                        {configurator.title === "Screen" && (
                                                            <img src={quotationContentData[0]['screen_image']?.link}
                                                                 alt="Screen"/>
                                                        )}
                                                    </div>
                                                    <div className="button-container">
                                                        <h2>{configurator.title}</h2>
                                                        <button
                                                            className="card-button-add"
                                                            onClick={() => handleConfiguratorData(configurator)}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowRight} size="1x"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {((quotationContentData.length === 0) || (Object.values(allConfigurators).length <= 0)) && (
                <div className="loading">
                    <div className="loading-icon"></div>
                    <h3>Offertemap is aan het laden...</h3>
                </div>
            )}
        </>
    );
};