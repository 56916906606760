import { useEffect, useState } from "react";
import "../../scss/Header.scss";
import { Link, useNavigate } from "react-router-dom";
import apiFetch from "@wordpress/api-fetch";
import { basicFetchRequest } from "../functions/functions.jsx";
import { useGlobalState } from "../GlobalContext.jsx";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";
import SliderUsp from "./SliderUsp.jsx";

export default function Header(props) {
  const isTabletOrMobile = useMediaQuery({ maxDeviceWidth: 768 });
  const {
    editProduct,
    setEditedProduct,
    fieldsData,
    setFieldsData,
    setSelectedConfigurator,
    setActiveField,
    setStatusQuotationFolder,
    setCompleteQuotation,
    quotationFolder,
    setQuotationFolder,
    configuratorHubspotUrl
  } = useGlobalState();
  const { data, selectedConfigurator, setHeaderData } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (!!setHeaderData) {
      const cacheExpiration = 60 * 60 * 1000; // 1 Hour

      // Check if cached data exists and is not expired
      const cachedData = localStorage.getItem("cachedConfiguratorData");
      if (cachedData) {
        const cachedDataParsed = JSON.parse(cachedData);
        const cacheTimestamp = cachedDataParsed.timestamp;

        if (Date.now() - cacheTimestamp < cacheExpiration) {
          setHeaderData(
            !!cachedDataParsed.headerData ? cachedDataParsed.headerData : {}
          );
        }
      }
    }
  }, []);

  const handleClickToHome = () => {
    setEditedProduct(""); // Remove editing in product when user clicks home button
    setFieldsData({}); // Remove old fieldsData
    setSelectedConfigurator(""); // Remove selected configurator
    setActiveField(0);
    setStatusQuotationFolder(false);
    setCompleteQuotation(false);
    navigate(`/${configuratorHubspotUrl}`);
  };

  const handleClickQuotationFolder = () => {
    setStatusQuotationFolder(false);
    setCompleteQuotation(false);
    setEditedProduct(""); // Reset edited product so we don't end up redirecting to the homepage
  };

  return (
    <>
      {!!Object.keys(data).length > 0 && (
        <>
          {isTabletOrMobile ? (
            <div className="usp-container-slider">
              <div>
                <SliderUsp texts={data.usp} />
              </div>
            </div>
          ) : (
            <div className="usp-container">
              <div className="container">
                <ul className="usp-block">
                  {data.usp.map((item, index) => {
                    return <li key={index}>{parse(item.usp)}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}
          <div className="header main-menu">
            <div className="container">
              <div className="left-container">
                <img
                  onClick={() => handleClickToHome()}
                  src={data.header.logo}
                  alt="Logo"
                />
                {!!selectedConfigurator && (
                  <h2>
                    {data.header.heading.replace(
                      "{product}",
                      selectedConfigurator
                    )}
                  </h2> // Remove?
                )}
              </div>
              <div className="right-container">
                {isTabletOrMobile ? (
                  <>
                    <Link
                      className={`button --primary quotation-icon-mobile ${
                        window.location.pathname === "/offertemap"
                          ? "active"
                          : ""
                      }`}
                      to={`/${configuratorHubspotUrl}/offertemap`}
                      onClick={() => handleClickQuotationFolder()}
                    >
                      {!!Object.keys(
                        JSON.parse(
                          localStorage.getItem("data_quotation") || "{}"
                        ).quotationFolder || {}
                      ).length > 0 && (
                        <span className="quotationFolderLength">
                          {
                            Object.keys(
                              JSON.parse(
                                localStorage.getItem("data_quotation") || "{}"
                              ).quotationFolder
                            ).length
                          }
                        </span>
                      )}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      className="button --primary quotation-icon"
                      to={`/${configuratorHubspotUrl}/offertemap`}
                      onClick={() => handleClickQuotationFolder()}
                    >
                      {!!Object.keys(
                        JSON.parse(
                          localStorage.getItem("data_quotation") || "{}"
                        ).quotationFolder || {}
                      ).length > 0 && (
                        <span className="quotationFolderLength">
                          {
                            Object.keys(
                              JSON.parse(
                                localStorage.getItem("data_quotation") || "{}"
                              ).quotationFolder
                            ).length
                          }
                        </span>
                      )}
                      {data.header.menu}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
