import {useState, useEffect, useRef} from "react";
import '../../../../scss/Fields.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import {handleConditionalLogic} from "../../../functions/functions";
import {handleInitialPriceOnFieldDataChange} from "../../../functions/price-functions";
import InfoPopup from "../../../popups/InfoPopup.jsx";


export default function RadioField(props) {
    const {
        item,
        option,
        index,
        stepIndex,
        fieldsData,
        setFieldsData,
        setPriceFromFields,
        priceFromFields,
        type,
        conditionalLogic,
        setConditionalLogic,
        appliedConditionalLogic,
        setAppliedConditionalLogic,
        allFieldsData,
        priceData,
        selectedConfigurator,
        setAllFieldsData,
        setLabelWhite,
        checkedInputStyle,
        setPriceUpdating,
        extraPriceRules,
        getSelectedConfigurator,
        extraFilledPrice,
        setExtraFilledPrice,
    } = props;

    const [activeInfoPopup, setActiveInfoPopup] = useState(false);
    const [extraPriceData, setExtraPriceData] = useState({});
    const [fieldPriceUpdate, setFieldPriceUpdate] = useState(null);
    const inputRef = useRef(null);
    const infoPopupRef = useRef(null);

    useEffect(() => {
        // update extraPriceRules on initial load.
        if(fieldPriceUpdate === null) {
            setFieldPriceUpdate(true);
        }
    },[]);

    useEffect(() => {
        // Index 0 means that we are on the first step, in this useEffect we will change the initial price after the fieldsData is set.
        // This makes sure that the initial price is updated when the user chooses ritsscreen or solar screen, since they come from a different table
        if (index === 0 && selectedConfigurator === "screen") {
            setPriceUpdating(true);
            handleInitialPriceOnFieldDataChange(priceFromFields, setPriceFromFields, fieldsData, priceData, selectedConfigurator);
            setTimeout(() => {
                setPriceUpdating(false);
            }, 1000);
        }
    }, [fieldsData])

    // Check if extraPriceRules are available and if the option key matches the extraPriceRules key
    useEffect(() => {
        if (!!option && Object.keys(extraPriceRules).length > 0) {
            const matchingRules = extraPriceRules.filter(rule =>
                rule['option_key'] === option['choice_key'] &&
                (getSelectedConfigurator === rule['witch_product'] || rule['witch_product'] === "all")
            );


            if (matchingRules.length > 0) {
                setExtraPriceData(prevData => {
                    const currentRules = prevData[item.label.toLowerCase()] ? [...prevData[item.label.toLowerCase()]] : [];

                    matchingRules.forEach(rule => {
                        // Check if a rule with the same option_key, price, and calc_method already exists
                        const isDuplicate = currentRules.some(existingRule =>
                            existingRule.option_key === rule['option_key'] &&
                            existingRule.price === rule['price'] &&
                            existingRule.calc_method === rule['calculation_type']
                        );

                        // Only push the rule if it's not a duplicate
                        if (!isDuplicate) {
                            currentRules.push({
                                'option_key': option['choice_key'],
                                'price': rule['price'],
                                'calc_method': rule['calculation_type']
                            });
                        }
                    });

                    return {
                        ...prevData,
                        [item.label.toLowerCase()]: currentRules
                    };
                });
            }
        }
    }, [option, extraPriceRules, getSelectedConfigurator, item.label]);

    // Apply extraPriceRules to available fields - Only for "prijs bijvoegen type"
    useEffect(() => {
        if (!!priceFromFields && !!extraPriceData[item.label.toLowerCase()] && fieldPriceUpdate) {
            let triggerUpdate = false;
            const shallowPriceFromFields = { ...priceFromFields };

            // Deep clone the rules to ensure we're not directly mutating state
            const rules = JSON.parse(JSON.stringify(extraPriceData[item.label.toLowerCase()] || []));

            rules.forEach(rule => {
                let transformedOptionKey = rule['option_key']
                    ? rule['option_key'].replace(/-/g, ' ').replace(/^\w/, c => c.toUpperCase())
                    : "";

                // Determine if the rule should apply
                const shouldExtraRuleApply = transformedOptionKey &&
                    fieldsData[item.label.toLowerCase()] === transformedOptionKey;

                if (shouldExtraRuleApply && !priceFromFields[item.label.toLowerCase()]) {
                    setPriceUpdating(true);
                    let extraPrice = parseFloat(rule['price']);
                    switch (rule['calc_method']) {
                        case "plus":
                            shallowPriceFromFields[item.label.toLowerCase()] = (shallowPriceFromFields[item.label.toLowerCase()] || 0) + extraPrice;
                            break;
                    }
                    rule.priceAdded = true;
                    triggerUpdate = true;
                } else if (!shouldExtraRuleApply && rule.priceAdded) {
                    // Rule previously applied but now conditions are not met
                    let extraPrice = parseFloat(rule['price']);
                    switch (rule['calc_method']) {
                        case "plus":
                            shallowPriceFromFields[item.label.toLowerCase()] = (shallowPriceFromFields[item.label.toLowerCase()] || 0) - extraPrice;
                            break;
                    }
                    rule.priceAdded = false;
                    triggerUpdate = true;
                }
            });

            if (triggerUpdate) {
                setPriceFromFields(shallowPriceFromFields);
                setFieldPriceUpdate(false);

                setTimeout(() => {
                    setPriceUpdating(false);
                }, 1000);
            }

            const filteredRules = rules.filter(rule => rule.calc_method === "price_times_height_in_meter");
            if (JSON.stringify(extraFilledPrice) !== JSON.stringify(filteredRules)) {
                setExtraFilledPrice({
                    ...extraFilledPrice,
                    [item.label.toLowerCase()]: filteredRules
                });
            }
        }
    }, [extraPriceData, priceFromFields, fieldsData, item, fieldPriceUpdate]);


    const handleInputInteraction = (type, option) => {
        const inputElement = inputRef.current;
        let triggerUpdate = false;

        if(typeof priceFromFields[item.label.toLowerCase()] !== "undefined" && priceFromFields[item.label.toLowerCase()] !== parseInt(inputElement.getAttribute('data-price'))) {
            setPriceUpdating(true);
            triggerUpdate = true;
        }

        if (inputElement && type === "radio" && !inputElement.disabled) {
            setFieldPriceUpdate(true);
            // If option price is available, add it to the price
            // Fill the conditionalLogic object with the field conditions, for question and option.
            handleConditionalLogic(allFieldsData, setConditionalLogic, conditionalLogic);

            const updatedFieldsData = { ...fieldsData };
            const { name, value } = inputElement;

            // Update the field with the new value
            updatedFieldsData[name] = value;

            setPriceFromFields({
                ...priceFromFields,
                [item.label.toLowerCase()]: !!option.price ? parseFloat(option.price) : parseFloat("0"),
            });

            // Check if the name is "afwerking" and if the value exists in fieldsData
            if (name === "afwerking" && fieldsData[name]) {
                // Function to handle the color fields if afwerking value is changed
                const alterFieldsData = { ...updatedFieldsData };

                // Clear values for keys containing 'kleuren'
                for (const key in alterFieldsData) {
                    if (key.includes('kleuren')) {
                        delete alterFieldsData[key];
                    }
                }

                if(inputElement.getAttribute('name') === 'type') {
                    alterFieldsData.type = {
                        'typeVal': alterFieldsData.type,
                        'typeKey': option['choice_key']
                    }
                }

                // Update the state with alteredFieldsData
                setFieldsData(alterFieldsData);
            } else if (name === "type" && inputElement.dataset.cKey === "solar rolluik" && fieldsData[name]) {
                // Function to handle the color fields if afwerking value is changed
                const alterFieldsData = { ...updatedFieldsData };

                // Change value in afwerking to "Afgeschuind" if product is a solar product
                for (const key in alterFieldsData) {
                    if (key.includes('afwerking')) {
                        alterFieldsData[key] = "Afgeschuind";
                    }
                }

                if(inputElement.getAttribute('name') === 'type') {
                    alterFieldsData.type = {
                        'typeVal': alterFieldsData.type,
                        'typeKey': option['choice_key']
                    }
                }
                // Update the state with alteredFieldsData
                setFieldsData(alterFieldsData);
            } else {

                if(inputElement.getAttribute('name') === 'type') {
                    updatedFieldsData.type = {
                        'typeVal': updatedFieldsData.type,
                        'typeKey': option['choice_key']
                    }
                }
                // Update the state with the regular updatedFieldsData
                setFieldsData(updatedFieldsData);
            }

            if(triggerUpdate) {
                setTimeout(() => {
                    setPriceUpdating(false);
                },1000);
            }
        }
    };

    const handleInfoPopupClick = (e) => {
        e.stopPropagation(); // Prevent the click from propagating to the outer radio field
        setActiveInfoPopup(!activeInfoPopup);
    }

    // Handle checked status
    let itemValue = fieldsData[item.label.toLowerCase()];
    if (typeof itemValue === 'object' && itemValue !== null && 'typeKey' in itemValue && 'typeValue' in itemValue) {
        itemValue = itemValue.typeKey || itemValue.typeVal;
    }

    return (
        <>
            <div key={item.label.toLowerCase() + index}
                 className={`input-${item.form_fields[0]["form_field"]["form_field_type"]} ${option.image ? 'with-image' : ""}${option['apply_conditional_logic'] ? option['apply_conditional_logic'] : ''}`}
                 style={fieldsData[item.label.toLowerCase()] === option.label ? checkedInputStyle : null}
                 onClick={(e) => {
                     handleInputInteraction(
                         !!type ? type : item.form_fields[0]["form_field"]["form_field_type"],
                         option
                     )
                 }}
            >
                <input
                    ref={inputRef}
                    id={item.label}
                    name={item.label.toLowerCase()}
                    type={!!type ? type : item.form_fields[0]["form_field"]["form_field_type"]}
                    value={option.label}
                    checked={(fieldsData[item.label.toLowerCase()] === option.label || !!itemValue && itemValue.typeKey === option['choice_key'])}
                    onChange={(e) => {
                        handleInputInteraction(
                            !!type ? type : item.form_fields[0]["form_field"]["form_field_type"],
                            option,
                        )
                    }}
                    disabled={!!option['apply_conditional_logic']}
                    data-price={!!option.price ? option.price : "0"}
                    data-ckey={!!option['choice_key'] ? option['choice_key'] : ""}
                />
                {option.image && (
                    <>
                        <img src={option.image} alt={option.label + "keuze"}/>
                        <label style={setLabelWhite ? {color: 'white'} : {}} htmlFor={item.label}>{option.label}</label>
                    </>
                )}
                {!option.image && (
                    <label htmlFor={item.label}>{option.label}</label>
                )}
                {!!option['most_chosen'] && (
                    <span style={fieldsData[item.label.toLowerCase()] === option.label ? {background:"var(--color-3)"} : {} }className="most-chosen">{option['most_chosen_label']}</span>
                )}
                {!!option.description && (
                    <div
                        ref={infoPopupRef}
                        onClick={(e) => handleInfoPopupClick(e)}
                        className="info-popup"
                    >
                        <FontAwesomeIcon icon={faCircleInfo} size="1x" style={{color: 'inherit'}} />
                    </div>
                )}
                {!!activeInfoPopup && (
                    <InfoPopup
                        trigger={activeInfoPopup}
                        text={option.description}
                        set={setActiveInfoPopup}
                        setTextParse={true}
                    />
                )}
            </div>
        </>
    );
}