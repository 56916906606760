import React, {useEffect, useState} from "react";
import "../../../scss/Preview.scss";
import {formatPriceLabel} from "../../functions/price-functions";
import {findImage, cropAndSetImage, findMatchingKey, findSelectedConfigurator} from "../../functions/functions.jsx";

export default function Preview(props) {
    const {data, price, fieldsData, sendImages, percentageData, activeField, priceUpdating,  percentageCalc, selectedConfigurator} = props;
    
    const [previewPrice, setPreviewPrice] = useState(null);
    const [outerImage, setOuterImage] = useState(null);
    const [innerImage, setInnerImage] = useState(null);
    const [labelPercentage, setLabelPercentage] = useState(null);

    useEffect(() => {
        window.scrollTo(0,0)
    }, []);

    useEffect(() => {
        const priceKeys = Object.keys(price);

        // If price length === 1, then we have to fetch the initial price from the price object
        if (priceKeys.length === 1) {
            setPreviewPrice(price[priceKeys[0]]);
        }

        // If price length > 1, then we have to loop through each price option and calculate the total price
        else if (priceKeys.length > 1) {
            const totalPrice = priceKeys.reduce((acc, key) => acc + price[key], 0);
            setPreviewPrice(totalPrice);
        }
    }, [price]);

    useEffect(() => {
        setLabelPercentage(getPercentage());
    },[percentageData, activeField, fieldsData]);

    useEffect(() => {
        const correctImageDataFromColors = data.find((objKey) => objKey.key === "kleuren-stap");

        const filterOptionsColorsByCondition = (formFields, conditionCallback) => {
            return formFields.flatMap((obj) =>
                obj.form_field && Array.isArray(obj.form_field.options_colors)
                    ? obj.form_field.options_colors
                        .filter((colorOption) => colorOption.acf && conditionCallback(colorOption.acf))
                        .map((colorOption) => colorOption.acf)
                    : []
            );
        }

        const housingData = filterOptionsColorsByCondition(correctImageDataFromColors.form_fields, (acf) => {
            if (acf["slats_houzing"] === "houzing") {
                return acf.houzing;
            }
            return false;
        });

        const inHousingData = filterOptionsColorsByCondition(correctImageDataFromColors.form_fields, (acf) => {
            if (acf["slats_houzing"] === "slats_canvas") {
                return acf.slats_canvas;
            }
            return false;
        });

        let housingImage = findImage(housingData, fieldsData['kleuren-omkasting-keuze'], "houzing", fieldsData['type']);
        let inHousingImage = findImage(inHousingData, !!fieldsData['kleuren-lamellen-keuze'] ? fieldsData['kleuren-lamellen-keuze'] : fieldsData['kleuren-doek-keuze'], "slats_canvas", fieldsData['type']);

        if (selectedConfigurator === "zonnescherm" && !!fieldsData['uitvoering'] && fieldsData['uitvoering']['typeKey'] === "premium") {
            housingImage = findImage(housingData, fieldsData['kleuren-omkasting-keuze'], "houzing", fieldsData['type'], true);
        }


        if (selectedConfigurator !== "zonnescherm") {
            let imageKey;
            switch (fieldsData['afwerking']) {
                case "Afgeschuind":
                    imageKey = "bevelled_casing";
                    break;
                case "Kwartrond":
                    imageKey = "quarter_round_casing"
                    break;
                case "Halfrond":
                    imageKey = "semicircular_casing"
                    break;
                case "Rechte kast":
                case "Vierkant":
                    imageKey = "straight_casing"
                    break;
                default:
                    imageKey = "bevelled_casing"
                    break;
            }
            if (!!housingImage && !!housingImage.houzing) {
                cropAndSetImage((!!housingImage.houzing[imageKey].url ? housingImage.houzing[imageKey].url : housingImage.houzing["bevelled_casing"].url), 150, 20, 550, 700, setOuterImage);
                cropAndSetImage(inHousingImage.slats_canvas.image.url, 150, 20, 550, 700, setInnerImage);
                sendImages((housingImage.houzing[imageKey].url), inHousingImage.slats_canvas.image.url);
            }
        } else {
            setOuterImage(housingImage.houzing.image.url);
            setInnerImage(inHousingImage.slats_canvas.image.url);
            sendImages(housingImage.houzing.image.url, inHousingImage.slats_canvas.image.url);
        }
    }, [fieldsData]);

    const getPercentage = () => {
        if(!!percentageCalc) {
            const matchingKeys = percentageCalc.filter(key =>
                Object.keys(fieldsData).some(fieldKey =>
                    fieldKey === key || fieldKey.startsWith(key + '-')
                )
            );

            if (matchingKeys.length > 0) {
                const percentage = (matchingKeys.length / percentageCalc.length) * 100;
                return Math.floor(percentage) + "%";
            } else {
                return "0%";
            }
        }
    };

    return (
        <div className="preview-container">
            <div className="preview-product">
                <img src={innerImage}/>
                <img src={outerImage}/>
                <div className="price-label" style={priceUpdating ? {backgroundColor:'white'} : {}}>
                    {priceUpdating
                        ? (
                            <>
                                <div className="loading">
                                    <div className="loading-icon"></div>
                                </div>
                            </>
                        )
                        : (
                            <>
                                <span>{formatPriceLabel(previewPrice, false)}</span>
                                <span className="description-text">totaal</span>
                            </>
                        )}
                </div>
            </div>
            <div className="preview-estimate" style={{display:'none'}}>
                <div className="card-label">
                    <div className="configurator-step">
                        <span className='loading-bar'><span style={{width: labelPercentage}}></span></span>
                        <span className="label-percentage">{labelPercentage}</span>
                        <span className="label-text">Nog even en hij hangt voor je raam!</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
