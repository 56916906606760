import {useEffect, useRef, useState} from "react";
import "../../scss/App.scss";
import Fields from "../../js/configurator/form/Fields.jsx";
import Content from "../../js/configurator/content/Content.jsx";
import Preview from "../../js/configurator/preview/Preview.jsx";
import {useGlobalState} from "../GlobalContext.jsx";
import Header from "../header/Header.jsx";
import {useNavigate, useParams} from 'react-router-dom';
import {
    addSessionIDToLocalStorage,
    addToDataLayer,
    applyAllDataInBatch,
    applyConditionalLogicV3,
    capitalizeText,
    fetchAllDataInBatchFromRESTAPI,
    findSelectedConfigurator,
    parseUrlParamsSingleConfiguration,
    setCorrectOptionsMeasurementFields
} from "../functions/functions.jsx";
import SHA256 from "crypto-js/sha256.js";
import Footer from "../footer/Footer.jsx";
import {handleInitialPriceOnFieldDataChange} from "../functions/price-functions.jsx";


export default function Configurator({ hubspotUrl, preAllConfigurators, preDesiredOrderStatus, preExtraPriceRules, preHeaderData, preStartContentData }) {
    const {
        configuratorData, setConfiguratorData,
        selectedConfigurator, setSelectedConfigurator,
        priceData, setPriceData,
        colorData, setColorData,
        engineData, setEngineData,
        priceFromFields, setPriceFromFields,
        conditionalLogic, setConditionalLogic,
        appliedConditionalLogic, setAppliedConditionalLogic,
        activeField, setActiveField,
        fieldsData, setFieldsData,
        quotationFolder, setQuotationFolder,
        headerData, setHeaderData,
        allConfigurators, setAllConfigurators,
        editProduct, setEditedProduct,
        startContentData, setStartContentData,
        priceUpdating, setPriceUpdating,
        configuratorHubspotUrl, setConfiguratorHubspotUrl,
        selectedColors, setSelectedColors,
        extraPriceRules, setExtraPriceRules
    } = useGlobalState();

    const [savedImageData, setSavedImageData] = useState({});
    const [desiredOrderStatus, setDesiredOrderStatus] = useState(false);
    const [percentageCalc, setPercentageCalc] = useState(null);

    const navigate = useNavigate();
    const reorderedObject = {};

    useEffect(() => {
        if(!!hubspotUrl) {
            setConfiguratorHubspotUrl(hubspotUrl);
        }
    }, [hubspotUrl]);

    // Handle dataLayer for GTM - Configurator page(view_item)
    useEffect(() => {
        if (!!allConfigurators && Object.keys(allConfigurators).length > 0) {
            // Create layerObject
            const layerData = {
                currency: "EUR",
                items: []
            };

            const priceKeys = Object.keys(priceFromFields);
            const totalPrice = priceKeys.reduce((acc, key) => acc + priceFromFields[key], 0);

            // Append correct data to layerData based on the fetched data
            const shallowFieldData = {...fieldsData};
            if (!!shallowFieldData.type) {
                delete shallowFieldData.type;
            }
            if (!!shallowFieldData.kleuren) {
                delete shallowFieldData.kleuren;
            }
            const itemData = {
                item_name: !!fieldsData.type && !!fieldsData.type.typeKey ? capitalizeText(fieldsData.type.typeKey) : capitalizeText(selectedConfigurator),
                price: totalPrice,
            }

            let hoogte_breedte = [];
            for (let key in shallowFieldData) {
                if (key === "afmeting-breedte" || key === "afmeting-hoogte") {
                    hoogte_breedte.push(shallowFieldData[key]);
                } else if (key === "kleuren-lamellen-keuze") {
                    itemData["kleur_lamellen"] = shallowFieldData[key];
                } else if (key === "kleuren-omkasting-keuze") {
                    itemData["kleur_omkasting"] = shallowFieldData[key];
                } else if (key === "bediening-motor") {
                    itemData["bediening_motor"] = shallowFieldData[key];
                } else if (key === "bediening-zender") {
                    itemData["bediening_zender"] = shallowFieldData[key];
                } else {
                    itemData[key] = shallowFieldData[key];
                }
            }
            if(!!hoogte_breedte && hoogte_breedte.length > 0) itemData["hoogte_breedte"] = hoogte_breedte.join(", ");

            layerData.value = totalPrice;
            layerData.items.push(itemData);

            // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
            if (!!layerData) {
                const currentStep = activeField + 1;
                addToDataLayer("view_item", layerData, "configurator", currentStep);
            }
        }
    }, [allConfigurators, fieldsData, priceFromFields, selectedConfigurator]);

    useEffect(() => {
        const handleApiCaching = async (reorderedObject) => {
            // Check if cached data exists
            const cachedData = localStorage.getItem('cachedConfiguratorData');

            if (cachedData) {
                const cachedDataParsed = JSON.parse(cachedData);
                const cachedChecksum = cachedDataParsed.checksum;

                // Calculate SHA-256 checksum for fetched data
                const fetchedChecksum = SHA256(JSON.stringify(reorderedObject)).toString();

                if (cachedChecksum === fetchedChecksum) {
                    // Data has not changed, use the cached data
                    setAllConfigurators(cachedDataParsed.data);
                    setDesiredOrderStatus(true);
                    setHeaderData(cachedDataParsed.headerData || {});
                    setStartContentData(cachedDataParsed.startContentData || {});

                    return true;
                }
            }

            return false;
        };

        const fetchDataAndApply = async () => {
            try {
                const pathName = window.location.pathname.split('/').filter(Boolean);
                const loadedConfigurator = pathName.pop();

                if(!!preAllConfigurators && !!preDesiredOrderStatus && !!preExtraPriceRules && !!preHeaderData && !!preStartContentData) {
                    setAllConfigurators(preAllConfigurators);
                    setDesiredOrderStatus((preDesiredOrderStatus || true));
                    setConfiguratorData(preAllConfigurators[loadedConfigurator].acf.steps);
                    setPriceData(preAllConfigurators[loadedConfigurator].priceData);
                    setEngineData(preAllConfigurators[loadedConfigurator].engineData);
                    setSelectedConfigurator(loadedConfigurator);
                    setExtraPriceRules(preExtraPriceRules);
                    setHeaderData(preHeaderData);
                    setStartContentData(preStartContentData);
                }

                handleApiCaching(reorderedObject).then((isCached) => {
                    if (isCached) {
                        addSessionIDToLocalStorage();
                    }
                });

            } catch (error) {
                console.error('Error processing data:', error);
            }
        };

        if(Object.keys(configuratorData).length <= 0 && Object.keys(headerData).length <= 0) {
            fetchDataAndApply();
        }
    }, [configuratorData, headerData]);

    useEffect(() => {
        if(!editProduct) {
            setFieldsData({});
        }
    }, [editProduct]);

    useEffect(() => {
        const handleUrlParameters = () => {
            if (Object.keys(allConfigurators).length === 0) return;

            if (window.location.search.includes("configurator=") && window.location.search.includes("configuration=")) {
                const savedUrlData = parseUrlParamsSingleConfiguration(window.location.search);

                if (savedUrlData && typeof savedUrlData === 'object') {
                    const { configurator, priceData, configuration } = savedUrlData;

                    if (configurator) {
                        const selectedConfigurator = findSelectedConfigurator(configurator, allConfigurators);
                        if (selectedConfigurator) {
                            // Check if the new value differs from the current state
                            if (selectedConfigurator !== selectedConfigurator) {
                                handleConfiguratorData(selectedConfigurator);
                            }
                        }
                    }

                    if (configuration) {
                        // Check if the new value differs from the current state
                        if (configuration !== fieldsData) {
                            setFieldsData(configuration);
                        }
                    }

                    if (priceData) {
                        // Check if the new value differs from the current state
                        if (priceData !== priceFromFields) {
                            setPriceFromFields(priceData);
                        }
                    }
                } else {
                    console.error("Invalid or missing URL data:", savedUrlData);
                }
            }
        };

        handleUrlParameters();
    }, [allConfigurators]);


    const handleConfiguratorData = (data) => {
        if (!!data) {
            setConfiguratorData(data.acf.steps);
            setPriceData(data.priceData);
            setEngineData(data.engineData);
            setSelectedConfigurator(data.title.toLowerCase());
        }
    };


    const gatherFieldsDataFromChild = (data, checkout = true) => {
        if (!!data) {
            if (checkout) {
                let newKey
                if(!!editProduct) {
                    newKey = editProduct;
                    setEditedProduct("");
                } else {
                    newKey = selectedConfigurator + "-" + (Object.keys(quotationFolder).length || 0);
                }
                // Add selectedConfigurator to new state object && priceData to new state object.
                if(selectedConfigurator === "zonnescherm") {
                    data.type = {
                        typeKey: "zonnescherm",
                        typeVal: "Zonnescherm"
                    }
                }
                data.data = {
                    ['nameConfigurator']: selectedConfigurator,
                    ['allPrices']: priceFromFields,
                    ['imageData']: savedImageData,
                    ['amount']: 1
                }

                // Rename 'bediening-motor' to 'motor'
                data.motor = data['bediening-motor'];
                delete data['bediening-motor'];

                // Rename 'bediening-zender' to 'zender'
                data.zender = data['bediening-zender'];
                delete data['bediening-zender'];

                const { housingType, housingSize } = handleInitialPriceOnFieldDataChange(
                    priceFromFields,
                    setPriceFromFields,
                    fieldsData,
                    priceData,
                    selectedConfigurator
                );

                if(!!housingSize && !!housingType) {
                    data.kast_type = capitalizeText(housingType);
                    data.kastmaat = housingSize;
                }

                // Add data to DataLayer - START
                // Create layerObject
                const layerData = {
                    currency: "EUR",
                    items: [

                    ]
                };

                const priceKeys = Object.keys(priceFromFields);
                const totalPrice = priceKeys.reduce((acc, key) => acc + priceFromFields[key], 0);
                layerData.value = totalPrice;

                let correctColorKey;
                let correctColorVal;

                if(data['kleuren-doek-keuze']) {
                    correctColorKey = "kleur_doek";
                    correctColorVal = "kleuren-doek-keuze";
                } else if (data['kleuren-omkasting-keuze']) {
                    correctColorKey = "kleur_lamellen";
                    correctColorVal = "kleuren-lamellen-keuze";
                }

                // Append correct data to layerData based on the fetched data
                const itemData = {
                    item_name: data.type.typeKey,
                    plaatsing: data.plaatsing,
                    afwerking: data.afwerking,
                    hoogte_breedte: [data['afmeting-hoogte'], data['afmeting-breedte']],
                    kleur_omkasting: data['kleuren-omkasting-keuze'],
                    [correctColorKey]: data[correctColorVal],
                    bediening: data.bediening,
                    montage: data.montage,
                    kast_type: housingType,
                    kastmaat: housingSize,
                    price: totalPrice
                };
                layerData.items.push(itemData);

                // Check if the layerData is not empty and the page is not localhost(localhost:3030 is missing dataLayer)
                if(!!layerData) {
                    addToDataLayer("add_to_cart", layerData,"configurator");
                }
                // Add data to DataLayer - END

                setQuotationFolder({
                    ...quotationFolder,
                    [newKey]: data,
                });
                navigate(`/${configuratorHubspotUrl}/offertemap`);
            }
        }
    }

    const gatherImageDataFromPreview = (outer, inner) => {
        if (!!outer && !!inner) {
            setSavedImageData({
                outerImage: outer,
                innerImage: inner
            });
        }
    };

    useEffect(() => {
        const labels = Object.values(configuratorData).map((item) => item.label.toLowerCase());
        if(!!labels) {
            setPercentageCalc(labels);
        }
    }, [configuratorData]);

    useEffect(() => {
        applyConditionalLogicV3(fieldsData, conditionalLogic, appliedConditionalLogic, setAppliedConditionalLogic);
    }, [conditionalLogic, fieldsData, appliedConditionalLogic]);

    return (
        <>
            {((Object.keys(allConfigurators).length <= 0) || (window.location.search.includes("share="))) && (
                <>
                    <div className="loading">
                        <div className="loading-icon"></div>
                        <h3>Configurator is aan het laden...</h3>
                    </div>
                </>
            )}
            {Object.keys(allConfigurators).length > 0 && (
                <>
                    <Header data={headerData} selectedConfigurator={selectedConfigurator} />
                    <div className={"main__configurator " + selectedConfigurator}>
                        {!!Object.keys(configuratorData).length > 0 && (
                            <>
                                <div className="preview">
                                    <Preview
                                        data={configuratorData}
                                        price={priceFromFields}
                                        fieldsData={fieldsData}
                                        sendImages={gatherImageDataFromPreview}
                                        percentageData={configuratorData}
                                        activeField={activeField}
                                        priceUpdating={priceUpdating}
                                        percentageCalc={percentageCalc}
                                        selectedConfigurator={selectedConfigurator}
                                    />
                                </div>
                                <div className="form-fields">
                                    <Fields
                                        data={configuratorData}
                                        setData={setConfiguratorData}
                                        priceData={priceData}
                                        colorData={colorData}
                                        setColorData={setColorData}
                                        engineData={engineData}
                                        selectedConfigurator={selectedConfigurator}
                                        setPriceFromFields={setPriceFromFields}
                                        priceFromFields={priceFromFields}
                                        setConditionalLogic={setConditionalLogic}
                                        conditionalLogic={conditionalLogic}
                                        appliedConditionalLogic={appliedConditionalLogic}
                                        sendFieldData={gatherFieldsDataFromChild}
                                        activeField={activeField}
                                        setActiveField={setActiveField}
                                        fieldsData={fieldsData}
                                        setFieldsData={setFieldsData}
                                        quotationFolder={quotationFolder}
                                        editProduct={editProduct}
                                        priceUpdating={priceUpdating}
                                        setPriceUpdating={setPriceUpdating}
                                        selectedColors={selectedColors}
                                        setSelectedColors={setSelectedColors}
                                        extraPriceRules={extraPriceRules}
                                    />

                                </div>
                            </>
                        )}
                    </div>
                    <Footer />
                </>
            )}
        </>
    );
}