import React, { useState, useEffect, useRef } from "react";
import '../../../../scss/Fields.scss'
import apiFetch from "@wordpress/api-fetch";
import { handleConditionalLogic, declareLabelKey } from "../../../functions/functions";
import { formatPriceLabel } from "../../../functions/price-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import InfoPopup from "../../../popups/InfoPopup.jsx";
import CustomSelectBox from "./inc/CustomSelectBox.jsx";

export default function EngineField(props) {
    const {
        item,
        index,
        fieldsData,
        setFieldsData,
        priceFromFields,
        setPriceFromFields,
        conditionalLogic,
        setConditionalLogic,
        allFieldsData,
        setAllFieldsData,
        engineData,
        checkedInputStyle,
        setPriceUpdating,
        appliedConditionalLogic
    } = props;

    const [enginesFieldData, setEnginesFieldData] = useState([]);
    const [transformedEngineFieldData, setTransformedEngineFieldData] = useState([]);
    const [activeInfoPopups, setActiveInfoPopups] = useState([]);
    const [toggles, setToggles] = useState([]); // Array of toggle states
    const [disabledEngineOptions, setDisabledEngineOptions] = useState([]);
    const inputRefs = useRef([]); // Create an array of refs
    const infoPopupRef = useRef(null);

    useEffect(() => {
        setActiveInfoPopups(Array(enginesFieldData.length).fill(false)); // Dynamically adjust based on enginesFieldData
    }, [enginesFieldData]);

    useEffect(() => {
        const fetchEnginesFromAPI = async () => {
            try {
                const data = engineData;

                // Fill ACF data to state.
                let enginesFieldDataArray = item['form_fields'].map((item) => {
                    const optionsDropdownField = item['form_field']['options_dropdown'];

                    if (optionsDropdownField.length > 0) {
                        return optionsDropdownField.map((dropDownOption) => {
                            const foundEngineData = Object.values(data).find((engine) => {
                                return engine.id === dropDownOption['option_object']['ID'];
                            });
                            return foundEngineData || null;
                        }).filter(engineData => engineData !== null);
                    }

                    return null;
                }).filter(engineDataArray => engineDataArray !== null);

                if (enginesFieldDataArray.length > 0) {
                    setEnginesFieldData(enginesFieldDataArray);
                }
            } catch (error) {
                console.error('There was an error:', error);
            }
        };
        fetchEnginesFromAPI().then(() => {
            // Handle conditional logic for the first time when the fields are loaded(mostly for editing order).
            handleConditionalLogic(allFieldsData, setConditionalLogic, conditionalLogic);
        });
    }, []);

    useEffect(() => {
        // Apply conditional logic to the enginesFieldDataArray. The (normal) logic applied is only for engine options.
        let shallowEnginesFieldData = [...enginesFieldData];
        shallowEnginesFieldData = shallowEnginesFieldData.map((engineDataArray, index) => {
            return engineDataArray.filter(engineData => {
                const labelKey = declareLabelKey(engineData['post_title']);
                // Check if the label exists in appliedConditionalLogic
                if (Object.keys(appliedConditionalLogic).includes(labelKey)) {
                    const conditionLogicKey = Object.keys(appliedConditionalLogic[labelKey])[0];
                    // If show_hide is "hide", return false to remove this option
                    if (appliedConditionalLogic[labelKey][conditionLogicKey]['show_hide'] === "hide") {
                        return false;
                    }
                    // @TODO: I need to remove hidden or disabled option from fieldsData, in order to handle shouldDisplayNextButton
                    if (appliedConditionalLogic[labelKey][conditionLogicKey]['show_hide'] === "disable") {
                        setDisabledEngineOptions((prevDisabledEngineOptions) => {
                            if (!prevDisabledEngineOptions.includes(labelKey)) {
                                return [
                                    ...prevDisabledEngineOptions,
                                    labelKey,
                                ];
                            }
                            return prevDisabledEngineOptions;
                        });
                    }
                }
                // If the label doesn't exist in appliedConditionalLogic or show_hide is not "hide", return true to keep this option
                return true;
            });
        });

        // Check if fieldsData has type with typeKey of zonnescherm and set is_premium based on uitvoering
        if (fieldsData.type && fieldsData.type.typeKey === 'zonnescherm') {
            shallowEnginesFieldData = shallowEnginesFieldData.map(engineDataArray => {
                return engineDataArray.map(engineData => {
                    if (fieldsData['uitvoering'] && fieldsData['uitvoering']['typeKey']) {
                        const isPremium = fieldsData['uitvoering']['typeKey'] === 'premium';
                        return {
                            ...engineData,
                            is_premium: isPremium
                        };
                    }
                    return engineData;
                });
            });
        }

        if (JSON.stringify(shallowEnginesFieldData) !== JSON.stringify(enginesFieldData) || JSON.stringify(shallowEnginesFieldData) !== JSON.stringify(transformedEngineFieldData)  ) {
            setTransformedEngineFieldData(shallowEnginesFieldData);
        }
    }, [fieldsData, enginesFieldData, appliedConditionalLogic]);

    useEffect(() => {
        // Remove 'Bediening-motor' when bediening field is filled with 'handbediend'.

        if (fieldsData['bediening'] === "Handbediend" && !!fieldsData['bediening-motor']) {
            const shallowFieldsData = {...fieldsData};
            delete shallowFieldsData['bediening-motor'];
            if (JSON.stringify(shallowFieldsData) !== JSON.stringify(fieldsData)) {
                setFieldsData(shallowFieldsData);
            }
        }
        handleConditionalLogic(allFieldsData, setConditionalLogic, conditionalLogic);
    }, [fieldsData]);

    const handleInputInteraction = (inputElement, index, item, optionIndex, optionChoiceKey, setMostchosen) => {
        let triggerUpdate = false;
        const updatedFieldData = { ...fieldsData };
        const shallowPriceFromFields = { ...priceFromFields };

        if (!!inputElement) {
            const dataPrice = inputElement.getAttribute('data-price');

            if (priceFromFields[inputElement.name] !== dataPrice) {
                setPriceUpdating(true);
                triggerUpdate = true;
            }

            if (typeof dataPrice !== "undefined" && !!dataPrice) {
                shallowPriceFromFields[inputElement.name] = parseFloat(dataPrice);
            }

            // Check if inputElement name is bediening, to ensure we have correct check value, if bediening is changed(only 2 values), remove motor and zender.
            if (inputElement.name === "bediening" && !!updatedFieldData[inputElement.name]) {
                if (updatedFieldData['bediening-motor']) {
                    delete updatedFieldData['bediening-motor'];
                    delete updatedFieldData['motor'];
                    delete shallowPriceFromFields['bediening-motor'];
                }
                if (updatedFieldData['bediening-zender']) {
                    delete updatedFieldData['bediening-zender'];
                    delete updatedFieldData['zender'];
                    delete shallowPriceFromFields['bediening-zender'];
                }
            } else if (inputElement.name === "bediening-motor" && !!updatedFieldData[inputElement.name]) {
                if (updatedFieldData['bediening-zender']) {
                    delete updatedFieldData['bediening-zender'];
                    delete updatedFieldData['zender'];
                    delete shallowPriceFromFields['bediening-zender'];
                }
            }

            updatedFieldData[inputElement.name] = {
                'typeVal': inputElement.value,
                'typeKey': optionChoiceKey
            };

            setFieldsData(updatedFieldData);

            if (JSON.stringify(shallowPriceFromFields) !== JSON.stringify(priceFromFields)) {
                setPriceFromFields(shallowPriceFromFields);
            }
        }

        if (item["form_fields"][index]["form_field"]["options_dropdown"][optionIndex]["most_chosen"]) {
            setMostchosen(item["form_fields"][index]["form_field"]["options_dropdown"][optionIndex]["most_chosen_label"]);
        } else {
            setMostchosen();
        }

        setToggle(index, false);

        if (triggerUpdate) {
            setTimeout(() => {
                setPriceUpdating(false);
            }, 1000);
        }
    };

    const toggleInfoPopup = (e, formFieldIndex) => {
        if(!!e) e.stopPropagation();
        setActiveInfoPopups(current =>
            current.map((isActive, index) => index === formFieldIndex ? !isActive : isActive)
        );
    };

    const setToggle = (customKey, value) => {
        setToggles((prevToggles) => ({
            ...prevToggles,
            [customKey]: value,
        }));
    };


    return (
        <>
            {!!transformedEngineFieldData &&
                transformedEngineFieldData.map((formFieldArray, formFieldIndex) => {
                    // Check if index is the same as the formFieldIndex, if it's not the case it should be hidden by conditional logic.
                    if (index !== formFieldIndex) {
                        // Remove value from fieldData if conditional logic is active, this makes sure we can continue to next question in configurator.
                        const shallowFieldData = { ...fieldsData };
                        for (let key in appliedConditionalLogic) {
                            // Handle key to remove -vraag from key
                            if(key.startsWith("bediening")) {
                                key = key.replace(/_/g, '-');
                                let keyParts = key.split("-");
                                if (keyParts.length > 1) {
                                    keyParts.pop();
                                    key = keyParts.join('-');
                                }
                                if (shallowFieldData.hasOwnProperty(key)) {
                                    delete shallowFieldData[key];
                                }
                            }
                        }
                        // Check if fieldsData has changed
                        if (JSON.stringify(shallowFieldData) !== JSON.stringify(fieldsData)) {
                            setFieldsData(shallowFieldData);
                        }
                        return null;
                    }
                    return (
                        <div
                            className={`input-radio`}
                            key={formFieldIndex}
                        >
                            <div style={{display: "inline-flex"}}>
                                <label className="engine-option-title" htmlFor={item.label.toLowerCase()}>
                                    {item["form_fields"][formFieldIndex]["form_field"].label}
                                </label>
                                {!!item["form_fields"][formFieldIndex]["form_field"].description && (
                                    <div
                                        onClick={(e) => toggleInfoPopup(e, formFieldIndex)}
                                        className="info-popup"
                                    >
                                        <FontAwesomeIcon icon={faCircleInfo} size="1x" style={{color: 'inherit'}} />
                                    </div>
                                )}
                            </div>
                            <div className="input-row-2">
                                <CustomSelectBox
                                    customKey={formFieldIndex}
                                    toggle={toggles}
                                    setToggle={setToggle}
                                    data={formFieldArray}
                                    dataKey={item.label.toLowerCase() + (formFieldIndex === 1 ? "-motor" : formFieldIndex === 2 ? "-zender" : "")}
                                    item={item}
                                    fieldsData={fieldsData}
                                    handleInputInteraction={handleInputInteraction}
                                    boxForStep={"engine"}
                                    index={index}
                                    disabledEngineOptions={disabledEngineOptions}
                                />
                            </div>
                            {activeInfoPopups[formFieldIndex] && (
                                <InfoPopup
                                    trigger={activeInfoPopups[formFieldIndex]}
                                    setTrigger={() => toggleInfoPopup(formFieldIndex)}
                                    text={item["form_fields"][formFieldIndex]["form_field"].description}
                                    setTextParse={true}
                                />
                            )}
                        </div>
                    );
                })
            }
        </>
    );
}
