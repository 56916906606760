import React, { useState, useEffect } from 'react';
import parse from "html-react-parser";

const SliderUsp = ({ texts, interval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % Object.keys(texts).length);
        }, interval);

        return () => clearInterval(intervalId);
    }, [currentIndex, interval, texts]);

    return (
        <div className="slideshow-container">
            {Object.values(texts).map((text, index) => (
                <div key={index} className={index === currentIndex ? 'slide active' : 'slide'}>
                    <p>{parse(Object.values(text)[0])}</p>
                </div>
            ))}
        </div>
    );
};

export default SliderUsp;
