import React, {useState, useEffect} from "react";
import '../../../../scss/ColorSelectField.scss'
import apiFetch from "@wordpress/api-fetch";
import CustomSelectBox from "./inc/CustomSelectBox.jsx";

export default function ColorSelectField(props) {
    const {item, type, fieldsData, setFieldsData, colorObject, setColorObject, setPriceFromFields, priceFromFields, selectedConfigurator, colorData, setPriceUpdating, formFieldIndex, selectedColors, setSelectedColors, allFieldsData, setAllFieldsData, conditionalLogic, appliedConditionalLogic } = props;
    const [selectedColor, setSelectedColor] = useState([]);
    const [opennessFactor, setOpennessFactor] = useState(false);
    const [sliceColors, setSliceColors] = useState(false);
    const [colorField, setColorField] = useState(null);
    const [toggles, setToggles] = useState([]); // Array of toggle states
    const [triggerCustomSelect, setTriggerCustomSelect] = useState(true);

    let currentLabel = item.form_fields[formFieldIndex]['form_field'].label.toLowerCase();
    if (!!currentLabel && currentLabel.includes(" ")) {
        currentLabel = currentLabel.split(" ")[0].toLowerCase();
    }

    const opennessFactorFields = {
        ['3%'] : '3% - Standaard',
        ['1%'] : '1% - Meest duurzaam',
        ['0%'] : '0% - Verduisterend',
    }

    useEffect(() => {
        setColorField(
            fieldsData['kleuren'] === "Kleuren selecteren"
                ? true
                : !fieldsData['kleuren']
                    ? null
                    : false
        );
    }, []);

    useEffect(() => {
        if(!fieldsData['kleuren'] && !!fieldsData['kleuren-omkasting-keuze'] && (!!fieldsData['kleuren-lamellen-keuze'] || !!fieldsData['kleuren-doek-keuze'])) {
            setFieldsData({
                ...fieldsData,
                'kleuren': "Kleur selecteren uit meest gekozen kleuren",
            });
        }
    }, [fieldsData]);

    useEffect(() => {
        checkIfProductIsScreen();
    },[colorData]);

    const handleInputInteraction = (e, option, section, index) => {
        let triggerUpdate = false;

        // if(e.target.value === "all-colors") {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     sliceColors ? setSliceColors(false) : setSliceColors(true);
        //     return;
        // }

        if(priceFromFields[e.target.getAttribute('name')] !== parseInt(option['color_price'])) {
            setPriceUpdating(true);
            triggerUpdate = true;
        }

        if (!!e.target) {
            if(typeof e.target.dataset.price !== "undefined" && !!e.target.getAttribute('name')) {
                const updatedSelectedColor = [...selectedColor];
                updatedSelectedColor[index] = { [index]: option['color_name'] };
                setSelectedColor(updatedSelectedColor);

                setPriceFromFields({
                    ...priceFromFields,
                    [e.target.getAttribute('name')]: !!e.target.dataset.price ? parseFloat(e.target.dataset.price) : parseFloat("0"),
                });
            }
            setFieldsData({
                ...fieldsData,
                [e.target.name]: e.target.value,
            });
            setToggle(index, false);
        }

        if(triggerUpdate) {
            setTimeout(() => {
                setPriceUpdating(false);
            },1000);
        }
    }

    const checkIfProductIsScreen = () => {
        // if (!!selectedConfigurator && selectedConfigurator !== "screen") {
        //     setOpennessFactor(false);
        // } else {
        //     setOpennessFactor(true);
        // }
        
        // Temporary disable opennessFactor select box for screens, until its clear what to do with this.
        setOpennessFactor(false);
    }

    const setToggle = (customKey, value) => {
        setToggles((prevToggles) => ({
            ...prevToggles,
            [customKey]: value,
        }));
    };

    const handleCustomColorSelect = (option, dataKey, customKey) => {
        if(triggerCustomSelect) {
            const updatedSelectedColor = [...selectedColor];
            updatedSelectedColor[customKey] = {[customKey]: option['color_name']};
            setSelectedColor(updatedSelectedColor);
            setPriceFromFields({
                ...priceFromFields,
                ['kleuren-omkasting-keuze']: !!option.price ? parseFloat(option.price) : parseFloat("0"),
            });
            setFieldsData({
                ...fieldsData,
                ['kleuren-omkasting-keuze']: option['color_name'],
            });
            setToggle(customKey, false);
            setTriggerCustomSelect(false);
        }
    };

    return (
        <div key={"select-color-component-" + formFieldIndex} className="select-color-component">
            {opennessFactor && (
                <>
                    {Object.values(opennessFactorFields).map((option, index, array) => (
                        <CustomSelectBox
                            customKey={"O" + index}
                            toggle={toggles}
                            setToggle={setToggle}
                            data={array}
                            dataKey={"kleuren-openheidsfactor"}
                            item={item}
                            fieldsData={fieldsData}
                            handleInputInteraction={handleInputInteraction}
                            boxForStep={"openness"}
                            index={index}
                            setFieldsData={setFieldsData}
                        />
                    ))}
                </>
            )}
            {!!item && Object.keys(item.form_fields[formFieldIndex]).length > 0 && (
                Object.entries(item.form_fields[formFieldIndex]).map(([colorKey, colorObj], index) => {
                    let fieldsDataTypeKey = !!fieldsData.type.typeKey && fieldsData.type.typeKey;
                    return (
                        <React.Fragment key={colorKey + "-" + formFieldIndex + "-" + index}>
                            {item.form_fields[formFieldIndex][colorKey]['type_product'] === fieldsDataTypeKey.replace(" ", "-") && (
                                <CustomSelectBox
                                    customKey={index}
                                    toggle={toggles}
                                    setToggle={setToggle}
                                    data={colorObj}
                                    dataKey={colorKey + "-" + formFieldIndex}
                                    item={item}
                                    fieldsData={fieldsData}
                                    sliceColors={sliceColors}
                                    handleInputInteraction={handleInputInteraction}
                                    handleCustomColorSelect={handleCustomColorSelect}
                                    boxForStep={"color"}
                                    index={index}
                                    selectedColors={selectedColors}
                                    setSelectedColors={setSelectedColors}
                                    mainKey={colorKey + index}
                                    setFieldsData={setFieldsData}
                                    currentLabel={currentLabel}
                                    priceFromFields={priceFromFields}
                                    appliedConditionalLogic={appliedConditionalLogic}
                                />
                            )}
                        </React.Fragment>
                    );
                })
            )}
        </div>
    );

}